import { EventoGenericoMedicion } from './EventoGenericoMedicion';
/**
 * Generate lead
 **/
 export class GenerateLeadMedicion extends EventoGenericoMedicion {
	readonly ACCION = 'generate_lead';
	readonly REASON = 'chatbot';
	constructor(){
		super();
		this.accion = this.ACCION;
		this.reason = this.REASON;
	}
}