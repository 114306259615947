import { EventoGenericoMedicion } from './EventoGenericoMedicion';
/**
 * View Item
 **/
 export class ViewItemMedicion extends EventoGenericoMedicion {
	readonly ACCION = 'view_item';
	readonly REASON = 'chatbot';
	constructor(){
		super();
		this.accion = this.ACCION;
		this.reason = this.REASON;
	}
}