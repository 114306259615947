import { DVTCollectChatV3 } from './DVTCollectChatV3';
import { PreguntaConRespuestas } from './PreguntaConRespuestas';
/**
 * Version 6 de collect Chat
 * Se agrega gestoría , alquileres y seguros
 */
 export class DVTCollectChatV6 extends DVTCollectChatV3{

	//Textos de mensaje
	readonly MENSAJE_USADO = 'Hola mi nombre es %usadoNombre% estoy interesado en adquirir un vehiculo usado  %usadoModelo%. A fin de que se puedan contactar les dejo  mi  correo electronico %usadoEmail%  y mi  telefono %usadoTelefono%. Me gustaría que me contacten ahora por WhatsApp.';
	readonly MENSAJE_CONVENCIONAL  = 'Hola mi nombre es %ceroKMNombreApellido% estoy interesado en adquirir un vehiculo modelo %ceroKMModelo%. A fin de que se puedan contactar les dejo  mi  correo electronico %ceroKMEmail%  y mi  telefono %ceroKMTelefono%. ';
	readonly MENSAJE_GESTORIA  = 'Hola mi nombre es %gestoriaNombreApellido%  quiero consulta el estado de un tramite de gestoria para un %gestoriaMotivo% por el dominio %gestoriaNumeroDominio% . Dejo  email %gestoriaEmail% y teléfono %gestoriaTelefono% a fin de que me contacten con información al respecto.';
  	readonly MENSAJE_ALQUILER  = 'Hola mi nombre es %alquilerNombreApellido% estoy interesado en alquilar un vehiculo modelo %alquilerModelo%. A fin de que se puedan contactar les dejo mi  correo electronico %alquilerEmail%  y mi  telefono %alquilerTelefono%.';
  	readonly MENSAJE_SEGUROS  = 'Hola mi nombre es %seguroNombreApellido% estoy interesado en contratar un seguro de %seguroMotivo%. A fin de que se puedan contactar les dejo mi  correo electronico %seguroEmail%  y mi  telefono %seguroTelefono%.';

	//preguntas por motivo
	protected preguntaMedioGestoria:PreguntaConRespuestas;
	protected preguntaMedioAlquiler:PreguntaConRespuestas;
	protected preguntaMedioSeguros:PreguntaConRespuestas;

	agregarPreguntasMedio() {
		super.agregarPreguntasMedio();
		//gestoria
		this.preguntaMedioGestoria = new PreguntaConRespuestas('XXXXX','medioContacto', []);
		this.agregarPreguntaConRespuestas(this.preguntaMedioGestoria);
		//convencional
		this.preguntaMedioConvencional = new PreguntaConRespuestas('76778D02-96F8-4F0F-9642-A3DC52F13408','medioContacto', []);
		this.agregarPreguntaConRespuestas(this.preguntaMedioConvencional);
		//usados
		this.preguntaMedioUsados = new PreguntaConRespuestas('89420343-53C7-452D-B765-632F18E99077','medioContacto', []);
		this.agregarPreguntaConRespuestas(this.preguntaMedioUsados);
		this.preguntaMedioPlanAhorro = new PreguntaConRespuestas('785962AA-488F-4D9C-8C4B-DD4C13B8C9A4','medioContacto', []);
		this.agregarPreguntaConRespuestas(this.preguntaMedioPlanAhorro);
		//alquiler
		this.preguntaMedioAlquiler = new PreguntaConRespuestas('F2BAED60-CACC-4A22-AAED-98E352276F61','medioContacto', []);
		this.agregarPreguntaConRespuestas(this.preguntaMedioAlquiler);
		//seguros
		this.preguntaMedioSeguros = new PreguntaConRespuestas('2F0E5AAA-D285-4F0D-9556-9AFE10157530','medioContacto', []);
		this.agregarPreguntaConRespuestas(this.preguntaMedioSeguros);
		//taller
		this.preguntaMedioTaller = new PreguntaConRespuestas('CCF79F88-1D0A-4240-887C-60F38C72840B','medioContacto', []);
		this.agregarPreguntaConRespuestas(this.preguntaMedioTaller);
		//repuestos
		this.preguntaMedioRepuestos = new PreguntaConRespuestas('34548EF0-C02F-4212-A155-0E1598C509F8','medioContacto', []);
		this.agregarPreguntaConRespuestas(this.preguntaMedioRepuestos);
		//
		this.agregarMensaje(this.MENSAJE_CONVENCIONAL);
		this.agregarMensaje(this.MENSAJE_GESTORIA);
		this.agregarMensaje(this.MENSAJE_ALQUILER);
		this.agregarMensaje(this.MENSAJE_SEGUROS);
	}

	protected agregarIdsPreguntas(){
		//GESTORIA
		this.agregarPregunta('385FEFF8-1DBC-40C1-B91D-8460E261E216','gestoriaMotivo');
		this.agregarPregunta('F05D036A-3559-4C5E-A1F7-C330056DBDBE','gestoriaNumeroDominio');
		this.agregarPregunta('9707B3A0-EC04-4FA4-A4FE-EFF2E88D143A','gestoriaNombreApellido');
		this.agregarPregunta('350C457F-3175-402D-892C-DD9941D50732','gestoriaTelefono');
		this.agregarPregunta('FBC8C551-CEAE-4860-87BB-D8DB68BDC862','gestoriaEmail');
		//
		//ALQUILER
		this.agregarPregunta('53B4ED13-BB81-4BE8-B3D7-2E1F5E027F8A','alquilerModelo');
		this.agregarPregunta('82C11A5D-CB29-438A-942B-E181CE71493A','alquilerNombreApellido');
		this.agregarPregunta('D998472D-AE2C-4278-A1D6-C0EEA7221631','alquilerTelefono');
		this.agregarPregunta('932EC32F-FC83-4731-83DD-680B6AEEE7B7','alquilerEmail');
		//SEGURO
		this.agregarPregunta('10C01FF6-AAE5-4C3D-909A-4DDDE09D7F31','seguroTipo');
		this.agregarPregunta('77B88F1D-8AD9-4441-839F-FFE02214A943','seguroNombreApellido');
		this.agregarPregunta('E66BDD6C-E6A0-47D0-A111-AE239AE57499','seguroTelefono');
		this.agregarPregunta('4ADAAD17-FDDD-4F0B-9238-982B340673CE','seguroEmail');
		//VENTAS CONVENCIONAL
		this.agregarPregunta('CB6AAC00-3398-4B36-A0C8-2E017838E1E6','ceroKMModelo');
		this.agregarPregunta('963A15E2-4109-41E8-BB19-80EAD8375C53','ceroKMNombreApellido');
		this.agregarPregunta('D63D4128-AB48-4355-920A-925234B6832E','ceroKMTelefono');
		this.agregarPregunta('39FA223B-52E0-4C69-994B-D06FFDC4C1E9','ceroKMEmail');
		//TURNOS TALLER
		this.agregarPregunta('06AB58EE-A121-4510-B8A7-400D9D3F45F1','tallerTurno');
		this.agregarPregunta('95B2F092-FC5F-4E4D-B1E4-B9A8033A66C4','tallerNombreApellido');
		this.agregarPregunta('56C2EF0C-66F9-48FF-89BE-7D23FBD8680C','tallerTelefono');
		this.agregarPregunta('84757219-5B37-4964-9BFC-EE8C3B6DBC16','tallerEmail');
		//REPUESTOS
		this.agregarPregunta('6E06102B-3F1C-4D18-B307-86D2D1AE2091','repuestoDetalle');
		this.agregarPregunta('3E59B7A3-9DA8-4D0A-915D-45E16C729E94','repuestoFoto');
		this.agregarPregunta('42204FBD-CF2F-44D4-8721-01C032872077','repuestoNombreApellido');
		this.agregarPregunta('878B9C61-4327-4DB3-B07B-8071600AB8CD','repuestoTelefono');
		this.agregarPregunta('D70DD58C-1874-407A-AB96-A5D2DB0316E2','repuestoEmail');
		// TOYOTA PLAN
		this.agregarPregunta('C07B7889-847B-4D36-A5FD-B65E67817333','planAhorroNombreApellido');
		this.agregarPregunta('2D1681FD-5D14-45E6-8AD3-E2063642A820','planAhorroModelo');
		this.agregarPregunta('29BACF26-C557-483B-9CD5-D65F6682FC2D','planAhorroTelefono');
		this.agregarPregunta('03AD0C01-C9BC-4905-9C5C-4BFA16A0F596','planAhorroEmail');
		//USADOS
		this.agregarPregunta('DD92AA0B-0AB2-4AC3-8604-68AD2EE049D4','usadoModelo');
		this.agregarPregunta('FD2BB284-22DF-4E28-84F1-EE42C471A0DB','usadoNombre');
		this.agregarPregunta('12E0DB39-CFD6-42D4-9EC0-989779D8A229','usadoTelefono');
		this.agregarPregunta('59987396-BFD5-4F01-8342-1EB76D4A5DEE','usadoEmail');
	}

	public agregarRespuestaMedioGestoria( telefonoWhatsApp:string)
	{
		let respuesta = this.respuestaGenericaWhatsApp(telefonoWhatsApp);
		this.preguntaMedioGestoria.addRespuesta(respuesta);
	}

	public agregarRespuestaMedioAlquileres( telefonoWhatsApp:string){
		const respuesta = this.respuestaGenericaWhatsApp(telefonoWhatsApp);
		this.preguntaMedioAlquiler.addRespuesta(respuesta);
	}

	public agregarRespuestaMedioSeguros( telefonoWhatsApp:string){
		const respuesta = this.respuestaGenericaWhatsApp(telefonoWhatsApp);
		this.preguntaMedioSeguros.addRespuesta(respuesta);
	}

	public agregarRespuestaAsesorMedioGestion( telefonoWhatsApp:string, nombre:string)
	{
		let respuesta = this.respuestaMedioAsesor(telefonoWhatsApp,nombre);
		this.preguntaMedioGestoria.addRespuesta(respuesta);
	}

	public agregarRespuestaAsesorMedioAlquileres( telefonoWhatsApp:string, nombre:string){
		const respuesta = this.respuestaMedioAsesor(telefonoWhatsApp,nombre);
		this.preguntaMedioAlquiler.addRespuesta(respuesta);
	}

	public agregarRespuestaAsesorMedioSeguros( telefonoWhatsApp:string, nombre:string){
		const respuesta = this.respuestaMedioAsesor(telefonoWhatsApp,nombre);
		this.preguntaMedioSeguros.addRespuesta(respuesta);
	}

}
