import { DVTCollectChat   } from './class/DVTCollectChat';
import { DVTCollectChatV1 } from './class/DVTCollectChatV1';
import { DVTCollectChatV2 } from './class/DVTCollectChatV2';
import { DVTCollectChatV3 } from './class/DVTCollectChatV3';
import { DVTCollectChatV4 } from './class/DVTCollectChatV4';
import { DVTCollectChatV5 } from './class/DVTCollectChatV5';
import { DVTCollectChatV6 } from './class/DVTCollectChatV6';
import { DVTCollectChatV7 } from './class/DVTCollectChatV7';
import { DVTCollectChatV8 } from './class/DVTCollectChatV8';
import {DVTCollectChatV9} from "./class/DVTCollectChatV9";
import {DVTCollectChatV10} from "./class/DVTCollectChatV10";

// Inicializar
let miVentana = window as any;

if(undefined != miVentana.dvtfcachatconfig.version){
	let chatLocal:DVTCollectChat;
	switch(miVentana.dvtfcachatconfig.version){
		case 1:
			chatLocal =  new DVTCollectChatV1(miVentana.dvtfcachatconfig.collectchat, miVentana.location.href);
		break;
		case 2:
			chatLocal =  new DVTCollectChatV2(miVentana.dvtfcachatconfig.collectchat, miVentana.location.href);
		break;
		case 3:
			chatLocal =  new DVTCollectChatV3(miVentana.dvtfcachatconfig.collectchat, miVentana.location.href);
			break;
		case 4:
			chatLocal =  new DVTCollectChatV4(miVentana.dvtfcachatconfig.collectchat, miVentana.location.href);
			break;
		case 5:
			chatLocal =  new DVTCollectChatV5(miVentana.dvtfcachatconfig.collectchat, miVentana.location.href);
			break;
		case 6:
			chatLocal =  new DVTCollectChatV6(miVentana.dvtfcachatconfig.collectchat, miVentana.location.href);
			break;
		case 7:
			chatLocal =  new DVTCollectChatV7(miVentana.dvtfcachatconfig.collectchat, miVentana.location.href);
			break;
		case 8:
			chatLocal =  new DVTCollectChatV8(miVentana.dvtfcachatconfig.collectchat, miVentana.location.href);
			break;
		case 9:
			chatLocal =  new DVTCollectChatV9(miVentana.dvtfcachatconfig.collectchat, miVentana.location.href);
			break;
    case 10:
      chatLocal =  new DVTCollectChatV10(miVentana.dvtfcachatconfig.collectchat, miVentana.location.href);
      break;
	}
	if(undefined != miVentana.dvtfcachatconfig.ready){
		miVentana.dvtfcachatconfig.ready(miVentana.dvtfcachatconfig.collectchat,chatLocal);
	}
}

