import { DVTCollectChatV1 } from './DVTCollectChatV1';
import { RespuestaMedioWhatsApp } from './RespuestaMedioWhatsApp';

/**
 * Version 2 de collect Chat
 * Posee preguntas predefinidas atados a los ids de pregunta mas genericos del v1 agregando una rama de usados
 */
 export  class DVTCollectChatV2 extends DVTCollectChatV1
 {
    readonly MENSAJE_USADO = 'Hola mi nombre es %usadoNombre% estoy interesado en adquirir un vehiculo usado  %usadoModelo%. A fin de que se puedan contactar les dejo  mi  correo electronico %usadoEmail%  y mi  telefono %usadoTelefono%. Me gustaría que me contacten ahora por WhatsApp.';
    //
    readonly VALOR_USADOS_DEFAULT = 'Quiero un Usado';
     //
    protected  valorRespuestaUsados:string;

    constructor(collectchat, urlActual:string) {
        super(collectchat, urlActual);
        //pregunta de motivo
        this.agregarPreguntaMotivo();
        this.agregarPreguntaMotivo();
        //agregar mensaje
        this.agregarMensaje(this.MENSAJE_USADO);
        //agregar ids de pregunta
        this.agregarIdsPreguntas();
        //medio
        this.agregarPreguntasMedio();
    }

     protected agregarIdsPreguntas()
     {
        super.agregarIdsPreguntas();
        //
         this.valorRespuestaUsados = this.VALOR_USADOS_DEFAULT;
        //USADO
        this.agregarPregunta('D6C93BAF-24DC-4014-B271-D5B5E1754167','usadoModelo');
        this.agregarPregunta('CE773B53-00B6-44DB-A14B-D1A2A61C6CFE','usadoNombre');
        this.agregarPregunta('52842085-38CB-44C9-824E-7D78AA60B694','usadoTelefono');
        this.agregarPregunta('3C6B0C11-BD91-45C3-B9FD-EFD282575770','usadoEmail');
     }

     public setRespuestaMotivoUsados(valorRespuesta:string){
         this.valorRespuestaUsados = valorRespuesta;
     }


     /**
      * Agrega un motivo con usado
      * @param telefonoWhatsApp
      */
     public agregarWhatsAppMotivoUsado(telefonoWhatsApp:string)
     {
         let respuesta = new RespuestaMedioWhatsApp(telefonoWhatsApp,this.valorRespuestaUsados);
         this.preguntaMotivo.addRespuesta(respuesta);
     }

 }
