import { EventoMedicionLead } from './EventoMedicionLead';

/**
 * Una medicion de lead que pertenece a whats app
 */
 export class EventoMedicionWhatsApp extends EventoMedicionLead{
	readonly ETIQUETA = 'whatsapp';
	constructor(){
		super();
		this.etiqueta = this.ETIQUETA;
	}

}