/**
 * Una respuesta
 */
 export class Respuesta {
	private determinaRedireccion:boolean;

	private valor: string;

	constructor(valor:string , determinaRedireccion:boolean = false){
		this.determinaRedireccion = determinaRedireccion;
		this.valor = valor;
	}

	/**
	 * Busca una respuesta entre las respuestas existentes
	 * @param respuestas
	 * @param respuesta
	 */
	public static find(respuestas: Respuesta[], seleccionCliente: string): Respuesta|null  {
		respuestas.forEach(function (r: Respuesta) {
			if (r.getValor() == seleccionCliente) {
				return  r;
			}
		});
		return null;
	}

	/**
	 * Indica si la respuesta tiene ruta de redireccion.
	 */
	public tieneRuta():boolean
	{
		return false;
	}

	/**
	 * Retorna el valor.
	 */
	public getValor(): string {
		return this.valor;
	}

	get Valor():string{
		return this.valor;
	}

	set Valor(valor:string){
		this.valor = valor;
	}

	public redireccionar():boolean{
		return this.determinaRedireccion;
	}

	public setDeterminaRedireccion(){
		this.determinaRedireccion = true;
	}
}
