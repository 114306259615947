import { DVTCollectChat } from './DVTCollectChat';
import { RespuestaMedioWhatsApp } from './RespuestaMedioWhatsApp';
import { PreguntaConRespuestas } from './PreguntaConRespuestas';
/**
 * Version 4 de collect Chat
 * Corto solo para venta de 0km en valores predefinidos
 */
 export class DVTCollectChatV4 extends DVTCollectChat
 {
     readonly MENSAJE_0KM = 'Hola mi nombre es %ceroKMNombre% estoy interesado en adquirir un vehiculo modelo %ceroKMModelo%. A fin de que se puedan contactar les dejo  mi  correo electronico %ceroKMEmail%  y mi  telefono %ceroKMTelefono%. Me gustaría que me contacten ahora por WhatsApp.';

     public loadCompleteCallback(): void {
         super.loadCompleteCallback();
     }

     constructor(collectchat, urlActual:string) {
         super(collectchat, urlActual);
         //MENSAJES
         this.agregarMensaje(this.MENSAJE_0KM);
         //PREGUNTAS GENERALES
         this.agregarPregunta('92E11BAC-8941-4655-AA95-03C336696077','ceroKMModelo');
         this.agregarPregunta('9707B3A0-EC04-4FA4-A4FE-EFF2E88D143A','ceroKMNombre');
         this.agregarPregunta('350C457F-3175-402D-892C-DD9941D50732','ceroKMTelefono');
         this.agregarPregunta('FBC8C551-CEAE-4860-87BB-D8DB68BDC862','ceroKMEmail');
         //FIN
     }

     /**
      * Define el email requerido para definir los valores
      * @param telefonoWhatsApp Telefono
      */
     public setWhatsApp(telefonoWhatsApp:string) {
         const respuesta = new RespuestaMedioWhatsApp( telefonoWhatsApp, this.RESPUESTA_MEDIO_WHATSAPP, true );
         const pregunta = new PreguntaConRespuestas( 'F3C892B6-E32E-45C8-8179-274B0344866E', 'medioContacto', [ respuesta ] );
         this.agregarPreguntaConRespuestas( pregunta );
     }

 }
