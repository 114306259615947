import {RespuestaMedioWhatsApp} from "./RespuestaMedioWhatsApp";

export class RespuestaAsesorWhatsApp extends RespuestaMedioWhatsApp{

    readonly PRINCIPIO_RESPUESTA_ASESOR = 'Ahora por Whats app con nuestro asesor ';
    protected nombreAsesor:string;
    protected principioRespuestaAsesor:string;

    /**
     * Genera una respuesta de asesor
     * @param telefonoWhatsApp
     * @param nombreAsesor
     * @param principioRespuestaAsesor
     */
    constructor(telefonoWhatsApp:string, nombreAsesor:string , principioRespuestaAsesor:string = null){
        super(telefonoWhatsApp, nombreAsesor, true);        
        this.nombreAsesor = nombreAsesor;        
        this.PrincipioRespuestaAsesor = principioRespuestaAsesor || this.PRINCIPIO_RESPUESTA_ASESOR;
    }

    /**
     * Modificar el principio de la respuesta  que se espera
     * @param string principioRespueta principio de respuesta
     */
    set PrincipioRespuestaAsesor(principioRespuesta:string){
         this.principioRespuestaAsesor = principioRespuesta;
         this.Valor = principioRespuesta+this.nombreAsesor;
        // console.log(this.Valor);
     }

    /**
     * Modificar el principio de la respuesta  que se espera
     * @return string principio de la cadena de respuesta esperada
     */
     get PrincipioRespuestaAsesor():string{
         return this.principioRespuestaAsesor;
     }

}