import { DVTCollectChat } from './DVTCollectChat';
import { RespuestaMedioWhatsApp } from './RespuestaMedioWhatsApp';
import { ParReemplazable } from './ParReemplazable';


/**
 * Version 5 de collect Chat
 * Más corto solo nombre y telefono , el modelo  se envia por webhook hardcodeado y la marca
 */
 export class DVTCollectChatV5 extends DVTCollectChat
 {
     readonly MENSAJE = 'Hola mi nombre es %landingNombre% estoy interesado en adquirir un vehiculo  %landingModelo%. A fin de que se puedan contactar les dejo  mi  telefono %landingTelefono%.';
     private telefonoWhatsApp:RespuestaMedioWhatsApp;
 
     public loadCompleteCallback(): void {
         super.loadCompleteCallback();
     }
 
     constructor(collectchat, urlActual:string) {
         super(collectchat, urlActual);
         //MENSAJES
         this.agregarMensaje(this.MENSAJE);
         //PREGUNTAS GENERALES
         this.agregarPregunta('C57D1743-08F2-411E-A46F-808BB70FD234','landingNombre');
         this.agregarPregunta('350C457F-3175-402D-892C-DD9941D50732','landingTelefono');
         this.definirModelo( ' que puedan recomendarme');
         //FIN
     }
 
     /**
      * Definir modelo
      */
     public definirModelo(nombreModelo:string){
         this.paresEncontrados.push(new ParReemplazable('landingModelo', nombreModelo));
     }
 
     /**
      * Define el email requerido para definir los valores
      * @param string telefonoWhatsApp Telefono
      */
     public definirWhatsApp(telefonoWhatsApp:string) {
         let respuesta = new RespuestaMedioWhatsApp(telefonoWhatsApp,'undefined',true);
         this.telefonoWhatsApp = respuesta;
     }
 
     /**
      * @inheritDoc
      */
     completeCallback() {
         const mensaje = ParReemplazable.reemplazar(this.paresEncontrados, this.MENSAJE);
         let url = this.telefonoWhatsApp.getUrl(mensaje);
         //evento
         this.lanzarEventoWhatsApp()
         //limpiar datos
         this.limpiarCharla();
         if (this.abrirBlank) {
             window.open(url, '_blank');
         } else {
             window.location.href = url
         }
     }
 
 }