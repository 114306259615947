import { Respuesta } from './Respuesta';
import { PreguntaSucursal } from './PreguntaSucursal';
import { PreguntaConRespuestas } from './PreguntaConRespuestas';

/**
 * si bien esta preguntando el medio define la sucursal que se ha seleccionado por exitir  asi como su key 
 */
export class PreguntaMedioSucursal extends PreguntaConRespuestas  {

    /** @var string medio en el que esta */
    protected sucursalKey: string;
    protected sucursalValor: string;    
    
    constructor(id: string, key: string, sucursalKey:string,sucursalValor:string, respuestas:Respuesta[]) {
        super(id, key, respuestas);
        this.sucursalKey = sucursalKey;
        this.sucursalValor = sucursalValor;
    }

    set SucursalValor(sucursal:string){
        this.sucursalValor = sucursal;
    }

    set SucursalKey(sucursalKey:string){
        this.sucursalKey = sucursalKey;
    }
    
    get DefineSucursal(): boolean{
		return true;
	}

	get SucursalKey() :string{
		return this.sucursalKey;
    }
    
	get SucursalValor() :string{
		return this.sucursalValor;
	}

}