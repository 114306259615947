import { EventoMedicionLead } from './EventoMedicionLead';
/**
 * Una medicion de lead que no es de whats app
 */
 export class EventoMedicionNoWhatsApp extends EventoMedicionLead{
	readonly ETIQUETA = 'nowhatsapp';
	constructor(){
		super();
		this.etiqueta = this.ETIQUETA;
	}
}