import { EventoMedicion } from './EventoMedicion';

/**
 * Una medicion que busca marcar una accion
 */
 export class EventoMedicionAccion extends EventoMedicion{
	constructor(accion:string, etiqueta:string){
		super();
		this.accion = accion;
		this.etiqueta = etiqueta;
	}
}