import { EventoMedicion } from './EventoMedicion';

/**
 * Una medicion de lead
 */
 export class EventoMedicionLead extends EventoMedicion {
	readonly ACCION = 'lead';
	constructor(){
		super();
		this.accion = this.ACCION;
	}
}