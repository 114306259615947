import { EventoMedicion } from './EventoMedicion';
import { EventoGenericoMedicion } from './EventoGenericoMedicion';
/**
 * Clase que se encarga de la gestion de los medios de analizar los eventos y dispararlos..
 */
 export  class Analitica {
	private _gtag:boolean;
	private _ga:boolean; 
	private _datalayer:boolean;

	constructor(){
		this._ga = this.gaExiste();
		this._gtag = this.gtagExiste();
		this._datalayer = this.tagManagerExiste();
	}

	get ga(){
		return this._ga;
	}

	public enabledGa(){
		this._ga = true;
	}


	get datalayer(){
		return this._datalayer;
	}

	public enableDatalayer(){
		this._datalayer = true;
	}

	get gtag(){
		return this._gtag;
	}

	public enabledGtag(){
		this._gtag = true;
	}


	/**
	 * Determina si existe analitycs gtag
	 */
	private tagManagerExiste(): boolean {
		//@ts-ignore
		return (typeof dataLayer != 'undefined');
	}

	/**
	 * Determina si existe analitycs ga a fin de lanzar los eventos
	 */
	private gaExiste(): boolean {
		//@ts-ignore
		return (typeof ga != 'undefined');
	}

	/**
	 * Determina si existe analitycs gtag
	 */
	private gtagExiste(): boolean {
		//@ts-ignore
		return (typeof gtag != 'undefined');
	}

	public anyTransportDefined(){
		return (this._ga || this._datalayer || this._gtag );
	}

	public lanzar(evento:EventoMedicion){
		if(!this.anyTransportDefined()){			
			throw new Error("No existen medios para disparar el evento");
		}
		const categoria = evento.categoria;
		const accion = evento.accion;
		const etiqueta = evento.etiqueta;
		if(this._gtag){
			//@ts-ignore
			gtag('event', accion, {'event_category': categoria,'event_label': etiqueta});
		}
		//
		if (this._datalayer) {
			//@ts-ignore
			dataLayer.push({'event':accion})
		}
		//
		if (this._ga) {
			//@ts-ignore
			ga('send', 'event', categoria, accion, etiqueta);
		}
	}

	public lanzarEventoGenerico(evento:EventoGenericoMedicion){
		if(this._gtag){
			//@ts-ignore
			gtag('event', evento.accion,{'contact_reason':evento.reason});
		}
	}
}