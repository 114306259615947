import { Respuesta } from './Respuesta';
/**
 * Las preguntas pueden tener varias respuestas
 * estas definen el telefono al que redireccionaren caso de elegirlas
 */
 export class RespuestaConRuta extends Respuesta{
	/**
	 * Telefono a definir como seleccionado si se determina esta respuesta
	 */
	protected url: string;

	constructor(url: string, valor:string, determinarRedireccion:boolean = false){
		super(valor, determinarRedireccion);
		this.url = url;
	}

	/**
	* Retorna el telefono definido para esta pregunta
	*/
	public getUrl(mensaje:string): string {
		let url = this.url;
		if (mensaje.length > 0) {			
			mensaje   = encodeURI(mensaje).replace(/&/i, '%26');
			//console.log(mensaje);			
			url += '?text=' + mensaje;
		}
		return url;
	}

	public tieneRuta(): boolean {
		return true;
	}
}