
/**
 * Par de valores que key => texto utilizable para reemplazar en el Mensaje.
 */
 export class ParReemplazable{
	private key: string;

	private texto: string;

	constructor(key: string, texto: string) {
		this.key = key;
		this.texto = texto;
	}

	/**
	 * Retonar una expresion a utilizar para reemplazar en Mensaje.
	 */
	public getExp() {
		return new RegExp('%' + this.key + '%');
	}

	/**
	 * Reemplaza los ParReemplazable en cadena de texto.
	 * @param pares Pares que se busca reemplazar en la cadena.
	 * @param cadena Cadena que contiene las key a ser reempalzadas por texto.
	 */
	public static reemplazar(pares: ParReemplazable[], cadena: string): string {
		for (const i in pares) {
			cadena = cadena.replace(pares[i].getExp(), pares[i].getTexto());
		}
		return cadena;
	}

	/**
	 * Retorna la key del par.
	 */
	public getKey(): string {
		return this.key;
	}

	/**
	 * Retorna el texto del par.
	 */
	public getTexto(): string {
		return this.texto;
	}
}
