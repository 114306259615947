import { DVTCollectChatV6 } from './DVTCollectChatV6';
import { PreguntaMedioSucursal } from "./PreguntaMedioSucursal";
import { Respuesta } from './Respuesta';
import { Pregunta } from './Pregunta';
import { ParReemplazable } from './ParReemplazable';
import { RespuestaAsesorWhatsApp } from './RespuestaAsesorWhatsApp';

/**
 * Version 7 de collect Chat
 * Se agrega la posibilidad de definir sucursal y telefonos para las mismas
 */
 export class DVTCollectChatV7 extends DVTCollectChatV6 {

	readonly MENSAJE_0KM_LOCAL = 'Hola mi nombre es %ceroKMNombreApellido% estoy interesado en adquirir un vehículo modelo %ceroKMModelo%.  Quisiera entregar un vehículo usado como parte de pago (%autoUsado%). A fin de que se puedan contactar les dejo  mi  correo electrónico %ceroKMEmail%  y mi  teléfono %ceroKMTelefono%. Me gustaría que me contacten ahora por WhatsApp.';
	readonly MENSAJE_PLAN_LOCAL      = 'Hola mi nombre es %planAhorroNombreApellido% quisiera consultar por un plan de ahorro para el modelo %planAhorroModelo%. A fin de que se puedan contactar les dejo  mi  correo electrónico %planAhorroEmail%  y mi  teléfono %planAhorroTelefono%. Me gustaría que me contacten ahora por WhatsApp.';
	readonly MENSAJE_TALLER_LOCAL    = 'Hola mi nombre es %tallerNombreApellido% quisiera solicitar un turno para taller %tallerTurno% . A fin de que se puedan contactar les dejo  mi  correo electrónico %tallerEmail% y mi  teléfono %tallerTelefono%. Me gustaría que me contacten ahora por WhatsApp.';
	readonly MENSAJE_REPUESTOS_LOCAL = 'Hola mi nombre es %repuestoNombreApellido% estoy interesado en adquirir un repuesto %repuestoDetalle% adjunto una imagen %repuestoFoto% . A fin de que se puedan contactar les dejo  mi  correo electrónico %repuestoEmail% y mi  teléfono %repuestoTelefono%. Me gustaría que me contacten ahora por WhatsApp.';
	readonly MENSAJE_CONSULTAS_LOCAL = 'Hola mi nombre es %consultaNombreApellido% quisiera realizar la siguiente consulta :  %consultaDetalle%  . A fin de que se puedan contactar les dejo  mi  correo electrónico %consultaEmail% y mi  teléfono %consultaTelefono%. Me gustaría que me contacten ahora por WhatsApp.';
	readonly MENSAJE_USADO_LOCAL = 'Hola mi nombre es %usadoNombreApellido% estoy interesado en adquirir un vehículo usado  %usadoModelo%. A fin de que se puedan contactar les dejo  mi  correo electrónico %usadoEmail%  y mi  teléfono %usadoTelefono%. Me gustaría que me contacten ahora por WhatsApp.';
	readonly MENSAJE_GESTORIA_LOCAL  = 'Hola mi nombre es %gestoriaNombreApellido%  quiero consulta el estado de un tramite de gestoria para un %gestoriaMotivo% . Dejo  email %gestoriaEmail% y teléfono %gestoriaTelefono% a fin de que me contacten con información al respecto.';
	readonly MENSAJE_ALQUILER_LOCAL  = 'Hola mi nombre es %alquilerNombreApellido% estoy interesado en alquilar un vehículo modelo %alquilerModelo%. A fin de que se puedan contactar les dejo mi  correo electrónico %alquilerEmail%  y mi  teléfono %alquilerTelefono%.';
	readonly MENSAJE_SEGUROS_LOCAL = 'Hola mi nombre es %seguroNombreApellido% estoy interesado en contratar un seguro de %seguroMotivo%. A fin de que se puedan contactar les dejo mi  correo electrónico %seguroEmail%  y mi  teléfono %seguroTelefono%.';
	//
	readonly MENSAJE_CONVENCIONAL_SUCURSAL  = 'Hola mi nombre es %ceroKMNombreApellido% estoy interesado en adquirir un vehículo modelo %ceroKMModelo% en la sucursal %ceroKMSucursal%. A fin de que se puedan contactar les dejo  mi  correo electrónico %ceroKMEmail%  y mi  teléfono %ceroKMTelefono%.';
	readonly MENSAJE_PLAN_SUCURSAL  = 'Hola mi nombre es %planAhorroNombreApellido% quisiera consultar por un plan de ahorro para el modelo %planAhorroModelo% en la sucursal %planAhorroSucursal%. A fin de que se puedan contactar les dejo  mi  correo electrónico %planAhorroEmail%  y mi  teléfono %planAhorroTelefono%. ';
	readonly MENSAJE_USADOS_SUCURSAL  = 'Hola mi nombre es %usadoNombre% estoy interesado en adquirir un vehículo usado  %usadoModelo% en la sucursal %usadoSucursal%. A fin de que se puedan contactar les dejo  mi  correo electrónico %usadoEmail%  y mi  teléfono %usadoTelefono%. ';
	readonly MENSAJE_TALLER_SUCURSAL  = 'Hola mi nombre es %tallerNombreApellido% quisiera solicitar un turno para taller con fecha %tallerTurno% en la sucursal %tallerSucursal%. A fin de que se puedan contactar les dejo  mi  correo electrónico %tallerEmail% y mi  teléfono %tallerTelefono%. ';
	readonly MENSAJE_REPUESTOS_SUCURSAL  = 'Hola mi nombre es %repuestoNombreApellido% estoy interesado en adquirir un repuesto %repuestoDetalle% en la sucursal %repuestoSucursal%, adjunto una imagen %repuestoFoto% . A fin de que se puedan contactar les dejo  mi  correo electrónico %repuestoEmail% y mi  teléfono %repuestoTelefono%.';
  	readonly MENSAJE_GESTORIA_SUCURSAL_USADOS = 'Hola mi nombre es %gestoriaNombreApellido%  quiero consulta el estado de un tramite de gestoria para un %gestoriaMotivo%  de la sucursal %gestoriaSucursal% con dominio %gestoriaNumeroDominio% . Dejo  email %gestoriaEmail% y teléfono %gestoriaTelefono% a fin de que me contacten con información al respecto.';
  	readonly MENSAJE_GESTORIA_SUCURSAL  = 'Hola mi nombre es %gestoriaNombreApellido%  quiero consulta el estado de un tramite de gestoria para un %gestoriaMotivo%  de la sucursal %gestoriaSucursal% . Dejo  email %gestoriaEmail% y teléfono %gestoriaTelefono% a fin de que me contacten con información al respecto.';
  	readonly MENSAJE_ALQUILER_SUCURSAL  = 'Hola mi nombre es %alquilerNombreApellido% estoy interesado en alquilar un vehículo modelo %alquilerModelo% en la sucursal %alquilerSucursal%. A fin de que se puedan contactar les dejo mi  correo electrónico %alquilerEmail%  y mi  teléfono %alquilerTelefono%.';
	readonly MENSAJE_SEGUROS_SUCURSAL = 'Hola mi nombre es %seguroNombreApellido% estoy interesado en contratar un seguro de %seguroMotivo% en la sucursal %seguroSucursal%. A fin de que se puedan contactar les dejo mi  correo electrónico %seguroEmail%  y mi  teléfono %seguroTelefono%.';
	//
  	readonly INICIO_MENSAJE_WHATS_APP = 'Ahora por WhatsApp con ';
	//
	protected preguntaSucursalMedioConvencional:PreguntaMedioSucursal;
	protected preguntaSucursalMedioPlanAhorro:PreguntaMedioSucursal;
	protected preguntaSucursalMedioUsados:PreguntaMedioSucursal;
	protected preguntaSucursalMedioTaller:PreguntaMedioSucursal;
	protected preguntaSucursalMedioRepuestos:PreguntaMedioSucursal;
	protected preguntaSucursalMedioAtencionCliente:PreguntaMedioSucursal;
	protected preguntaSucursalMedioGestoria:PreguntaMedioSucursal;
	protected preguntaSucursalMedioAlquiler:PreguntaMedioSucursal;
	protected preguntaSucursalMedioSeguros:PreguntaMedioSucursal;

	 constructor(chat: any, urlActual: string) {
		super(chat, urlActual);
		this.preguntaSucursalMedioConvencional = null;
		this.preguntaSucursalMedioPlanAhorro = null;
		this.preguntaSucursalMedioUsados = null;
		this.preguntaSucursalMedioTaller = null;
		this.preguntaSucursalMedioRepuestos = null;
		this.preguntaSucursalMedioGestoria = null;
		this.preguntaSucursalMedioAlquiler = null;
		this.preguntaSucursalMedioSeguros = null;
		this.inicioRespuestaWhatsApp = this.INICIO_MENSAJE_WHATS_APP;
		//
		this.agregarMensaje(this.MENSAJE_0KM_LOCAL);
		this.agregarMensaje(this.MENSAJE_PLAN_LOCAL);
		this.agregarMensaje(this.MENSAJE_USADO_LOCAL);
		this.agregarMensaje(this.MENSAJE_TALLER_LOCAL);
		this.agregarMensaje(this.MENSAJE_REPUESTOS_LOCAL);
		this.agregarMensaje(this.MENSAJE_GESTORIA_LOCAL);
		this.agregarMensaje(this.MENSAJE_ALQUILER_LOCAL);
		this.agregarMensaje(this.MENSAJE_SEGUROS_LOCAL);
		this.agregarMensaje(this.MENSAJE_CONSULTAS_LOCAL);
		//
		this.agregarMensaje(this.MENSAJE_CONVENCIONAL_SUCURSAL);
		this.agregarMensaje(this.MENSAJE_PLAN_SUCURSAL);
		this.agregarMensaje(this.MENSAJE_USADOS_SUCURSAL);
		this.agregarMensaje(this.MENSAJE_TALLER_SUCURSAL);
		this.agregarMensaje(this.MENSAJE_REPUESTOS_SUCURSAL);
		this.agregarMensaje(this.MENSAJE_GESTORIA_SUCURSAL);
		this.agregarMensaje(this.MENSAJE_GESTORIA_SUCURSAL_USADOS);
		this.agregarMensaje(this.MENSAJE_ALQUILER_SUCURSAL);
		this.agregarMensaje(this.MENSAJE_SEGUROS_SUCURSAL);
	 }

	protected procesarRespuestaEncontrada(preguntaEncontrada:Pregunta , respuestaCliente:string)
	{
		//console.info(preguntaEncontrada);
		this.procesarRespuestasEncontradas(preguntaEncontrada, respuestaCliente);
		if (preguntaEncontrada instanceof PreguntaMedioSucursal)
		{
			this.log(`Pregunta de sucursal ${preguntaEncontrada.getId()}`);
			this.procesarRespuestaSucursal(preguntaEncontrada);
		}

		const par = new ParReemplazable(preguntaEncontrada.getKey(), respuestaCliente);
		this.paresEncontrados.push(par);
	}

	/**
	 * Al contestar a la pregunta en cuestion por contestarla esta definiendo en que sucursal esta realizando esa pregunta
	 * @param preguntaEncontrada
	 */
	protected procesarRespuestaSucursal(preguntaEncontrada:PreguntaMedioSucursal) {
		this.log('Pregunta de sucursal encontrada : ');
		this.log(preguntaEncontrada);
		this.paresEncontrados.push(new ParReemplazable(preguntaEncontrada.SucursalKey, preguntaEncontrada.SucursalValor));
	}

	/**
	 * Pregunta que define el medio de la sucursal para gestoria
	 * @param string id Id de la pregunta.
	 * @param string key Palabra que se utilizara para reemplazar la variable en el texto de whats app.
	 * @param Respuesta respuestas Respuestas que tiene que tener los datos.
	 */
	 public agregarPreguntaSucursalMedioGestoria(id: string, sucursalValor: string, respuestas: Respuesta[] = null) {
		this.preguntaSucursalMedioGestoria = new PreguntaMedioSucursal(id, 'gestoriaMedio', 'gestoriaSucursal', sucursalValor, respuestas);
		this.agregarPreguntaConRespuestas(this.preguntaSucursalMedioGestoria);
	}

	/**
	* Agrega una respuesta al medio gestoria
	* @param telefonoWhatsApp Telefono de whats app del asesor de la sucursal
	* @param nombreAsesor  Nombre del asesor
	*/
	public agregarRespuestaPreguntaSucursalMedioGestoria(telefonoWhatsApp:string, nombreAsesor:string) {
		if (!this.preguntaSucursalMedioGestoria) {
			throw new Error('No puede agregarse una respuesta a gestoria sin asignar una pregunta de gestoria.');
		}
		const respuesta = new RespuestaAsesorWhatsApp(telefonoWhatsApp, nombreAsesor, this.inicioRespuestaWhatsApp);
		this.log(` Agregando respuesta a gestion ${nombreAsesor} `);
		this.preguntaSucursalMedioGestoria.addRespuesta(respuesta);
	}

	/**
	 * Pregunta que define el medio de la sucursal para gestoria
	 * @param string id Id de la pregunta.
	 * @param sucursalValor Nombre de sucursal
	 * @param Respuesta respuestas Respuestas que tiene que tener los datos.
	 */
	 public agregarPreguntaSucursalMedioPlan(id:string , sucursalValor:string, respuestas:Respuesta[]=null){
		 this.preguntaSucursalMedioPlanAhorro = new PreguntaMedioSucursal(id, 'planAhorroMotivo', 'planAhorroSucursal', sucursalValor, respuestas);
		 this.agregarPreguntaConRespuestas(this.preguntaSucursalMedioPlanAhorro);
	}


	/**
	* Agrega una respuesta al medio plan
	* @param telefonoWhatsApp Telefono de whats app del asesor de la sucursal
	* @param nombreAsesor  Nombre del asesor
	*/
	public agregarRespuestaPreguntaSucursalMedioPlan(telefonoWhatsApp:string, nombreAsesor:string) {
		if (!this.preguntaSucursalMedioPlanAhorro) {
			throw new Error('No puede agregarse una respuesta a gestoria sin asignar una pregunta de plan de ahorro.');
		}
		this.preguntaSucursalMedioPlanAhorro.addRespuesta(new RespuestaAsesorWhatsApp(telefonoWhatsApp, nombreAsesor, this.inicioRespuestaWhatsApp));
	}

	/**
	 * Pregunta que define el medio de la sucursal para usados
	 * @param string id Id de la pregunta.
	 * @param string key Palabra que se utilizara para reemplazar la variable en el texto de whats app.
	 * @param Respuesta respuestas Respuestas que tiene que tener los datos.
	 */
	public agregarPreguntaSucursalMedioUsados(id:string , sucursalValor:string,  respuestas:Respuesta[]=null){
		this.preguntaSucursalMedioUsados = new PreguntaMedioSucursal(id, 'usadoMotivo', 'usadoSucursal', sucursalValor, respuestas);
		this.agregarPreguntaConRespuestas(this.preguntaSucursalMedioUsados);
	}

	/**
	* Agrega una respuesta al medio plan
	* @param telefonoWhatsApp Telefono de whats app del asesor de la sucursal
	* @param nombreAsesor  Nombre del asesor
	*/
	public agregarRespuestaPreguntaSucursalMedioUsados(telefonoWhatsApp:string, nombreAsesor:string) {
		if (!this.preguntaSucursalMedioUsados) {
			throw new Error('No puede agregarse una respuesta a gestoria sin asignar una pregunta de usados.');
		}
		this.preguntaSucursalMedioUsados.addRespuesta(new RespuestaAsesorWhatsApp(telefonoWhatsApp, nombreAsesor, this.inicioRespuestaWhatsApp));
	}

	/**
	 * Pregunta que define el medio de la sucursal para alquileres
	 * @param string id Id de la pregunta.
	 * @param string key Palabra que se utilizara para reemplazar la variable en el texto de whats app.
	 * @param Respuesta respuestas Respuestas que tiene que tener los datos.
	 */
	 public agregarPreguntaSucursalMedioAlquileres(id:string ,sucursalValor:string,  respuestas:Respuesta[]=null){
		 this.preguntaSucursalMedioAlquiler = new PreguntaMedioSucursal(id, 'alquilerMotivos', 'alquilerSucursal', sucursalValor, respuestas);
		 this.agregarPreguntaConRespuestas(this.preguntaSucursalMedioAlquiler);
	}

	/**
	* Agrega una respuesta al medio alquileres
	* @param telefonoWhatsApp Telefono de whats app del asesor de la sucursal
	* @param nombreAsesor  Nombre del asesor
	*/
	public agregarRespuestaPreguntaSucursalMedioAlquileres(telefonoWhatsApp:string, nombreAsesor:string) {
		if (!this.preguntaSucursalMedioAlquiler) {
			throw new Error('No puede agregarse una respuesta a gestoria sin asignar una pregunta de alquileres.');
		}
		this.preguntaSucursalMedioAlquiler.addRespuesta(new RespuestaAsesorWhatsApp(telefonoWhatsApp, nombreAsesor, this.inicioRespuestaWhatsApp));
	}

	/**
	 * Pregunta que define el medio de la sucursal para repuestos
	 * @param string id Id de la pregunta.
	 * @param string key Palabra que se utilizara para reemplazar la variable en el texto de whats app.
	 * @param Respuesta respuestas Respuestas que tiene que tener los datos.
	 */
	 public agregarPreguntaSucursalMedioRepuestos(id:string , sucursalValor:string,  respuestas:Respuesta[]=null){
		 this.preguntaSucursalMedioRepuestos = new PreguntaMedioSucursal(id, 'repuestoMotivo', 'repuestoSucursal', sucursalValor, respuestas);
		 this.agregarPreguntaConRespuestas(this.preguntaSucursalMedioRepuestos);
	}

	/**
	 * Agrega una respuesta al medio respuestos
	 * @param telefonoWhatsApp Telefono de whats app del asesor de la sucursal
	 * @param nombreAsesor  Nombre del asesor
	 */
	public agregarRespuestaPreguntaSucursalMedioRepuestos(telefonoWhatsApp:string, nombreAsesor:string) {
		if (!this.preguntaSucursalMedioRepuestos) {
			throw new Error('No puede agregarse una respuesta a gestoria sin asignar una pregunta de repuestos.');
		}
		this.preguntaSucursalMedioRepuestos.addRespuesta(new RespuestaAsesorWhatsApp(telefonoWhatsApp, nombreAsesor, this.inicioRespuestaWhatsApp));
	}

	/**
	 * Pregunta que define el medio de la sucursal para Atencion al Cliente
	 * @param string id Id de la pregunta.
	 * @param string key Palabra que se utilizara para reemplazar la variable en el texto de whats app.
	 * @param Respuesta respuestas Respuestas que tiene que tener los datos.
	 */
	public agregarPreguntaSucursalMedioAtencionCliente(id:string , sucursalValor:string,  respuestas:Respuesta[]=null){
		this.preguntaSucursalMedioAtencionCliente = new PreguntaMedioSucursal(id, 'atencionMotivo', 'atencionSucursal', sucursalValor, respuestas);
		this.agregarPreguntaConRespuestas(this.preguntaSucursalMedioAtencionCliente);
	}

	/**
	 * Agrega una respuesta al medio respuestos
	 * @param telefonoWhatsApp Telefono de whats app del asesor de la sucursal
	 * @param nombreAsesor  Nombre del asesor
	 */
	public agregarRespuestaPreguntaSucursalMedioAtencionCliente(telefonoWhatsApp:string, nombreAsesor:string) {
		if (!this.preguntaSucursalMedioAtencionCliente) {
			throw new Error('No puede agregarse una respuesta a gestoria sin asignar una pregunta de Atencio al Cliente.');
		}
		this.preguntaSucursalMedioAtencionCliente.addRespuesta(new RespuestaAsesorWhatsApp(telefonoWhatsApp, nombreAsesor, this.inicioRespuestaWhatsApp));
	}

	/**
	 * Pregunta que define el medio de la sucursal para taller
	 * @param string id Id de la pregunta.
	 * @param string key Palabra que se utilizara para reemplazar la variable en el texto de whats app.
	 * @param Respuesta respuestas Respuestas que tiene que tener los datos.
	 */
	 public agregarPreguntaSucursalMedioTaller(id:string ,sucursalValor:string,  respuestas:Respuesta[]){
		 this.preguntaSucursalMedioTaller = new PreguntaMedioSucursal(id, 'tallerMotivo', 'tallerSucursal', sucursalValor, respuestas);
		 this.agregarPreguntaConRespuestas(this.preguntaSucursalMedioTaller);
	}


	/**
	* Agrega una respuesta al medio taller
	* @param telefonoWhatsApp Telefono de whats app del asesor de la sucursal
	* @param nombreAsesor  Nombre del asesor
	*/
	public agregarRespuestaPreguntaSucursalMedioTaller(telefonoWhatsApp:string, nombreAsesor:string) {
		if (!this.preguntaSucursalMedioTaller) {
			throw new Error('No puede agregarse una respuesta a gestoria sin asignar una pregunta de taller.');
		}
		this.preguntaSucursalMedioTaller.addRespuesta(new RespuestaAsesorWhatsApp(telefonoWhatsApp, nombreAsesor, this.inicioRespuestaWhatsApp));
	}


	/**
	 * Pregunta que define el medio de la sucursal para seguros
	 * @param string id Id de la pregunta.
	 * @param string key Palabra que se utilizara para reemplazar la variable en el texto de whats app.
	 * @param Respuesta respuestas Respuestas que tiene que tener los datos.
	 */
	 public agregarPreguntaSucursalMedioSeguros(id:string , sucursalValor:string,  respuestas:Respuesta[]){
		 this.preguntaSucursalMedioSeguros = new PreguntaMedioSucursal(id, 'seguroMotivo', 'seguroSucursal', sucursalValor, respuestas);
		 this.agregarPreguntaConRespuestas(this.preguntaSucursalMedioSeguros);
	}

	/**
	* Agrega una respuesta al medio taller
	* @param telefonoWhatsApp Telefono de whats app del asesor de la sucursal
	* @param nombreAsesor  Nombre del asesor
	*/
	public agregarRespuestaPreguntaSucursalMedioSeguros(telefonoWhatsApp:string, nombreAsesor:string) {
		if (!this.preguntaSucursalMedioSeguros) {
			throw new Error('No puede agregarse una respuesta a gestoria sin asignar una pregunta de seguros.');
		}
		this.preguntaSucursalMedioSeguros.addRespuesta(new RespuestaAsesorWhatsApp(telefonoWhatsApp, nombreAsesor, this.inicioRespuestaWhatsApp));
	}


	/**
	 * Pregunta que define el medio de la sucursal para convencional
	 * @param string id Id de la pregunta.
	 * @param string key Palabra que se utilizara para reemplazar la variable en el texto de whats app.
	 * @param Respuesta respuestas Respuestas que tiene que tener los datos.
	 */
	 public agregarPreguntaSucursalMedioConvencional(id:string , sucursalValor:string,  respuestas:Respuesta[]){
		 this.preguntaSucursalMedioConvencional = new PreguntaMedioSucursal(id, 'ceroKMMotivo', 'ceroKMSucursal', sucursalValor, respuestas);
		 this.agregarPreguntaConRespuestas(this.preguntaSucursalMedioConvencional);
	}

	/**
	* Agrega una respuesta al medio taller
	* @param telefonoWhatsApp Telefono de whats app del asesor de la convencional
	* @param nombreAsesor  Nombre del asesor
	*/
	public agregarRespuestaPreguntaSucursalMedioConvencional(telefonoWhatsApp:string, nombreAsesor:string) {
		if (!this.preguntaSucursalMedioConvencional) {
			throw new Error('No puede agregarse una respuesta a gestoria sin asignar una pregunta de convencional.');
		}

		this.log('inicio actual '+this.inicioRespuestaWhatsApp);
		this.preguntaSucursalMedioConvencional.addRespuesta(new RespuestaAsesorWhatsApp(telefonoWhatsApp, nombreAsesor, this.inicioRespuestaWhatsApp));
	}

}
