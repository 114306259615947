import { EventoMedicion } from './EventoMedicion';
import { RespuestaConRuta } from './RespuestaConRuta';
import { Pregunta } from './Pregunta';
import { ParReemplazable } from './ParReemplazable';
import { Analitica } from './Analitica';
import { EventoMedicionWhatsApp } from './EventoMedicionWhatsApp';
import { EventoMedicionNoWhatsApp } from './EventoMedicionNoWhatsApp';
import { PreguntaConRespuestas } from './PreguntaConRespuestas';
import { ViewItemMedicion } from './ViewItemMedicion';
import { RespuestaMedioWhatsApp } from './RespuestaMedioWhatsApp';
import { EventoMedicionRedireccion } from './EventoMedicionRedireccion';
import { EventoMedicionAccion } from './EventoMedicionAccion';
import { GenerateLeadMedicion } from './GenerateLeadMedicion';
import { Respuesta } from './Respuesta';
import { RespuestaAsesorWhatsApp } from './RespuestaAsesorWhatsApp';

/**
 * Clase principal que gestiona la charla por javascript
 */
 export  abstract class DVTCollectChat {
	readonly RESPUESTA_MEDIO_WHATSAPP = 'Ahora por WhatsApp';

	protected urlSitio:string;
	protected debug: boolean;
	protected testCustom: boolean;
	protected abrirBlank: boolean;
	protected respuestaRedireccion: RespuestaConRuta;
	protected preguntas: Pregunta[];
	protected mensajes: string[];
	protected chat: any;
	protected paresEncontrados: ParReemplazable[];
	protected preguntaInicial: number;
	protected urlActual: string;
	protected analitica:Analitica;
	protected eventoWhatsApp:EventoMedicionWhatsApp;
	protected eventoNoWhatsApp:EventoMedicionNoWhatsApp;
	protected categoriaEventos:string;
	protected inicioRespuestaWhatsApp: string;
	protected respuestaWhatsAppGenerica: string;
	protected eliminarRespuestasVacias:boolean;
	protected eliminarRespuestasGuionMedio:boolean;

	protected constructor(chat:any, urlActual:string) {
		this.urlSitio = '/';
		this.debug = false;
		this.testCustom = false;//@test
		this.abrirBlank = false;
		this.preguntas = [];
		this.mensajes = [];
		this.limpiarCharla();
		this.chat = chat;
		this.preguntaInicial = 1;
		this.urlActual = urlActual;
		this.analitica = new Analitica();
		this.eventoWhatsApp = new EventoMedicionWhatsApp();
		this.eventoNoWhatsApp = new EventoMedicionNoWhatsApp();
		this.categoriaEventos = null;
		this.respuestaWhatsAppGenerica =  this.getRespuestaMedioWhatsApp();
		this.eliminarRespuestasGuionMedio = false;
		this.eliminarRespuestasVacias = false;
		this.log( ` respuesta definida ${this.respuestaWhatsAppGenerica}`);
	}

	public setEliminarRespuestaGuionMedio(estado:boolean):void
	{
		this.eliminarRespuestasGuionMedio = estado;
	}

	public setEliminarRespuestasVacias(estado:boolean):void
	{
		this.eliminarRespuestasVacias = estado;
	}

	public limpiarMensajes(){
		this.mensajes = [];
	}

	public limpiarPreguntasCargadas(){
		this.preguntas = [];
	}

	protected  getRespuestaMedioWhatsApp(){
		return this.RESPUESTA_MEDIO_WHATSAPP;
	}

	public useGa(){
		this.analitica.enabledGa();
	}

	public useGtag(){
		this.analitica.enabledGtag();
	}

	public useDatalayer(){
		this.analitica.enableDatalayer();
	}

	/**
	 * Limpia los valores de la charla para volver a inicializarla.
	 */
	protected limpiarCharla() {
		this.respuestaRedireccion = null;
		this.paresEncontrados = [];
	}

	/**
	 * Define la url del sitio.
	 */
	public setUrlSitio(urlSitio: string){
		this.urlSitio = urlSitio;
	}

	/**
	 * Define target Blanck. Permite abrir whatsapp en otra ventana (default: false)
	 * @param abrirBlank
	 */
	public setTargetBlank(abrirBlank: boolean){
		this.abrirBlank = abrirBlank;
		
		if (this.testCustom) {
			this.log('Target _blank WA ↗️: ' + this.abrirBlank);
		}
	}

	/**
	 * Agrega un mensaje a la lista de mensajes
	 * @param mensaje
	 */
	public agregarMensaje(mensaje: string)
	{
		this.mensajes.push(mensaje);
	}

	/**
	 * Abre el chat en una pregunta particular
	 * @param preguntaNumero
	 */
	public abrirEnPreguntaPreguntaDeterminada(preguntaNumero:number = 1){
		this.log('Solicitando apertura '+preguntaNumero);
		this.preguntaInicial = preguntaNumero;
		this.chat.open();
	}

	/**
	 * Agrega una pregunta a la lista de preguntas unicas por key, reemplazara la pregunta en caso de encontrarla por su key
	 * @param id Identificador de la pregunta en CC
	 * @param key indice que determina la ubicacion de la pregunta en el array
	 */
	public agregarPregunta(id: string, key: string): Pregunta {
		const indice = this.agregarPreguntaALista(new Pregunta(id, key));
		return this.preguntas[indice];
	}

	/**
	 * Agregar una pregunta a la lista de preguntas retornando el
	 * @param pregunta
	 */
	public agregarPreguntaALista(pregunta:Pregunta): number {
		let indice: number = Pregunta.findKey(this.preguntas, pregunta.getId());
		if (indice === null) {
			indice = this.preguntas.push(pregunta) - 1;
		}else{
			this.preguntas[indice] = pregunta;
		}
		return indice;
	}

	/**
	 * Agrega una pregunta a la lista de pregunstas unicas por key
	 * @param pregunta  Pregunta a agregar con las respuestas ya sumadas
	 */
	public agregarPreguntaConRespuestas( pregunta:PreguntaConRespuestas):number {
		return this.agregarPreguntaALista(pregunta);
	}

	/**
	* Busca una pregunta en el listado
	* @param id Id de la pregunta
	* @returns  number
	*/
	public buscarPreguntaPorId(id: string):number{
		return Pregunta.findKey(this.preguntas, id) || null;
	}

	protected agregarRespuesta(id: string, respuesta: Respuesta) {
		let indice = this.buscarPreguntaPorId(id);
		if (!indice) {
			throw Error(`La pregunta ${id} no existe.`);
		}
		let pregunta = this.preguntas[indice];
		if (pregunta instanceof PreguntaConRespuestas) {
			pregunta.addRespuesta(respuesta);
			this.preguntas[indice] = pregunta;
		}
	}

	public agregarRespuestaWhatsAppGenerica(id: string, telefonoWhatsApp:string) {
		//devuelve una respuesta  de whats app  
		let respuesta = this.respuestaGenericaWhatsApp(telefonoWhatsApp);
		this.agregarRespuesta(id, respuesta);
	}

	public agregarRespuestaWhatsAppAsesor(id: string, telefonoWhatsApp:string, nombreAsesor:string ) {
		let respuesta = this.respuestaMedioAsesor(telefonoWhatsApp,nombreAsesor);
		this.agregarRespuesta(id, respuesta);
	}


	set InicioRespuestaWhatsApp(inicio: string) {
		this.log('El valor de inicio ahora es : '+inicio)
		this.inicioRespuestaWhatsApp = inicio;
	}

	public setRespuestaWhatsAppGenerica(respuestaWhatsApp:string){
		this.respuestaWhatsAppGenerica = respuestaWhatsApp;
		this.log(` La respuesta generica definida es ${respuestaWhatsApp}`);
	}

	public respuestaConRuta(url: string, valor:string, determinarRedireccion:boolean = true)
	{
		return new RespuestaConRuta(url, valor,determinarRedireccion);
	}

	/**
	 *  Agrega una pregunta con respuesta de redireccionde url
	 */
	public agregarPreguntaConRuta(key:string, url: string, valor:string){

		this.agregarPreguntaConRespuestas( new PreguntaConRespuestas(key, 'urlRedireccion', [this.respuestaConRuta(url, valor,true)]));
	}

	public respuestaGenericaWhatsApp(telefonoWhatsApp:string) : RespuestaMedioWhatsApp
	{
			return new RespuestaMedioWhatsApp(telefonoWhatsApp, this.respuestaWhatsAppGenerica,true);
	}

	public respuestaMedioAsesor(telefonoWhatsApp:string, nombre:string)
	{
			return new RespuestaAsesorWhatsApp(telefonoWhatsApp, nombre,this.inicioRespuestaWhatsApp);
	}

	/**
	 * Procesa la respuesta del usuario.
	 * @param detalles
	 */
	public messageCallback(detalles): void {
		if (!this.testCustom) {
			this.log('Respueta del usuario: ' + detalles.question + ', ' + detalles.answer);
		}
		else {
			this.log('▶ user : ' + detalles.answer);
			this.log('▶ ID pr: ' + detalles.question);
		}

		if(detalles.question == this.preguntaInicial){
			this.log('limpiando charla');
			this.limpiarCharla();
		}
		this.procesarRespuesta(detalles.question, detalles.answer);
	};

	/**
	 * Procesa la inicializacion al momento de completar la carga del chat.
	 */
	public loadCompleteCallback(): void {
		this.irAPreguntaInicial();
		if (!this.testCustom) {
			this.log("carga completa pregunta inicial "+this.preguntaInicial);
		}
		else {
			//test custom inicial
			console.log("%c🐞 Test Debugger DVTchat 🐞", "border: 1px solid red; font-weight: 900; color: red; background: #ffff !important;border-radius: 1.3em; padding: 10px 17px;transition: width 2s; margin-right: 2rem;");

		}
	}

	/**
	 *  Va a la pregunta inicial que corresponda
	 */
	public irAPreguntaInicial()
	{
		if(this.preguntaInicial > 1)
		{
			this.chat.message(this.preguntaInicial);
		}else{
			this.chat.clear();
		}
	}

	/**
	 * Procesa la respuesta de CC  como punto de entrada
	 * @param preguntaId id de la pregunta en CC
	 * @param respuestaCliente respuesta otorgada por el usuario
	 */
	protected procesarRespuesta(preguntaId: string, respuestaCliente: string) {
		let preguntaEncontrada = Pregunta.find(this.preguntas, preguntaId);
		if (null !== preguntaEncontrada) {
			this.procesarRespuestaEncontrada(preguntaEncontrada, respuestaCliente);
		}
	}

	/**
	 * Procesa la respuesta que llego , en base a la pregunta que se encontro por la key  y el texto de para comparar la respuesta
	 * @param Pregunta preguntaEncontrada Pregunta que se contesto
	 * @param string respuestaCliente Texto de la respuesta
	 */
	protected procesarRespuestaEncontrada(preguntaEncontrada:Pregunta , respuestaCliente:string)
	{
		if (!this.testCustom) {
			this.log('Pregunta encontrada: '+preguntaEncontrada);
		}
		this.procesarRespuestasEncontradas(preguntaEncontrada,respuestaCliente);
		this.paresEncontrados.push(new ParReemplazable(preguntaEncontrada.getKey(), respuestaCliente));
	}

	/**
	 * Procesar si existe una respuesta encontrada entre las preguntas que tiene asociadas la respuesta que se encontro
	 * @param Pregunta preguntaEncontrada Pregunta que se contesto
	 * @param string respuestaCliente Texto de la respuesta
	 */
	protected procesarRespuestasEncontradas(preguntaEncontrada:Pregunta,respuestaCliente:string){

		if (this.testCustom) {
			console.log("⇄🟡 Variable: " + preguntaEncontrada['key']);
			console.log('     ∵∵∵∵∵∵∵∵∵∵∵∵∵∵∵∵∵∵∵∵∵∵∵∵∵∵∵∵∵∵∵∵∵∵∵');
		}

		if (preguntaEncontrada.tieneRespuestas()) {
			let preguntaConRespuesta = preguntaEncontrada as PreguntaConRespuestas;
			this.log('🟢 Tiene respuestas');
			
			let respuestaEncontrada = preguntaConRespuesta.find(respuestaCliente);
			this.log('Respuesta encontrada para:');
			if (this.testCustom) {
				this.log(' ➡️ ' + respuestaCliente);
				this.log(' ➡️ ' + respuestaEncontrada);
			}
			this.log(respuestaEncontrada);

			if (null != respuestaEncontrada ) {
				if (respuestaEncontrada.tieneRuta()) {
					this.respuestaRedireccion =  respuestaEncontrada as RespuestaConRuta;
				}
				if(null !== this.respuestaRedireccion && respuestaEncontrada.redireccionar()){
					this.respuestaRedireccion.setDeterminaRedireccion();
				}
			}
		} else {
			this.log(`NO tiene respuestas`);
		}
	}

	/**
	 * Callback al momento de completar el chat
	 * redirecciona a whats app y/o lanza el lead
	 *
	 * @returns
	 */
	public completeCallback() {

		this.log('  Terminando Chat 🏁');

		if (null !== this.respuestaRedireccion && this.respuestaRedireccion.redireccionar()) {
			let mensaje = this.generarMensaje();
			let url = this.respuestaRedireccion.getUrl(mensaje);
			this.log('url WA  ' + url);
			if (this.respuestaRedireccion instanceof RespuestaMedioWhatsApp) {
				this.lanzarEventoWhatsApp()
			}else{
				this.lanzarEvento(new EventoMedicionRedireccion(url));
			}
			//limpiar datos
			this.limpiarCharla();
			if (this.abrirBlank) {
				window.open(url, '_blank');
			} else {
				window.location.href = url
			}
		}else{
			this.lanzarEventoNoWhatsApp();
		}
		this.limpiarCharla();
	}

	/**
	 *  funciones para notificar al abrir el chat
	 */
	public openCallback() {
		const evento = new EventoMedicionAccion('apertura','inicial');
		this.lanzarEvento(evento);
		this.analitica.lanzarEventoGenerico( new ViewItemMedicion());
	}

	/**
	 * Determina cual es el mensaje correspondiente
	 * Busca entre los mensajes el que  tengan la mayor cantidad de pares encontradas
	 * @param paresEncontrados
	 * @param mensajes Mensaje a utilizar
	 */
	private determinarMensaje(paresEncontrados: ParReemplazable[], mensajes: string[]): string {
		let mensajeEncontrado: string = mensajes[0];
		let lastMerito = 0, merito = 0;
		// pasando por todos los mensajes
		mensajes.forEach(function (m: string) {
			merito = 0;
			//console.info('Pares encontrados: ',paresEncontrados);
			// pasando por todas los pares
			paresEncontrados.forEach(function (p: ParReemplazable) {
				merito += (p.getExp().test(m)) ? 1 : 0;
			});
			//buscar la maxima hasta encontrar el mensaje
			if (merito > lastMerito) {
				//console.log(` mensaje definido : ${m} mensaje : ${merito}  > ${lastMerito} `);
				mensajeEncontrado = m;
				lastMerito = merito;
			}
			//console.log(`mensaje : ${m} merito : ${merito} `);
		});
		if (this.testCustom) {
			this.log('📫 Mensaje Encontrado:');
			console.log('%c ' + mensajeEncontrado, 'border: 1px solid #25d366; font-weight: 900; color: #25d366; background: black !important;border-radius: 1.3em; padding: 10px 17px;transition: width 2s; margin-right: 2rem;');
		}
		return mensajeEncontrado;
	}

	/**
	 * Determina mensaje y completa variables con pares
	 */
	protected generarMensaje(): string
	{
		let mensaje: string = '';
	//filtrar los vacios
	if(this.eliminarRespuestasVacias){
	this.limpiarRespuestasVacias();
	}
	if(this.eliminarRespuestasGuionMedio){
	this.limpiarRespuestasGuionMedio();
	}
		if(this.mensajes.length > 0 ) {
			mensaje =  ParReemplazable.reemplazar(this.paresEncontrados, this.determinarMensaje(this.paresEncontrados, this.mensajes));
		}
		return mensaje;
	}

	/**
	 * Eliminar los valores que tiene vacios
	 */
	public limpiarRespuestasVacias() :void
	{
		this.paresEncontrados = this.paresEncontrados.filter(par =>  par.getTexto().length > 0 );
	}

	/**
	 * Eliminar los valores que tiene guion medio. Es el valor que envío al saltar la carga de una imagen
	 */
	public limpiarRespuestasGuionMedio() :void
	{
		this.paresEncontrados =   this.paresEncontrados.filter(par => par.getTexto() != '-');
	}

	/**
	 * Manejador de logs
	 * @param texto texto a mostrar en el log
	 */
	protected log(texto: Object): void {
		if (this.isDebug()) {
			console.log(texto);
		}
	}

	/**
	 * Lanza un evento
	 * @param accion Accion del evento.
	 * @param etiqueta Etiqueta del evento.
	 */
	private lanzarEvento(evento:EventoMedicion) {
		if( this.categoriaEventos != null){
			evento.categoria = this.categoriaEventos;
		}
		try {
			this.analitica.lanzar(evento);
		} catch (error) {
			this.log(error);
		}
	}

	/**
	 *
	 * @param categoria
	 */
	public  definirCategoriaEventos(categoria:string) {
		this.categoriaEventos = categoria;
	}

	/**
	 * Determina si se esta en modo Debug.
	 */
	public isDebug(): boolean {
		return this.debug;
	}

	/**
	 * Determina el modo Debug.
	 * @param debug Estado al que pasar el modo.
	 */
	public setDebug(debug: boolean): void {
		this.debug = debug;
	}

	/**
	 * Determina si abrir los links en otra ventana.
	 * @param abrirBlank
	 */
	public setAbrirBlank(abrirBlank: boolean): void {
		this.abrirBlank = abrirBlank;
	}

		/**
		 * Lleva el chat a la pregunta preguntaNumero
		 * @param string El path en el que se busca definir esa pregunta inicial.
		 * @param number preguntaNumero Numero de pregunta en la que empezar.
		 */
		public saltarAPregunta(pathBuscado:string, preguntaNumero:number)
		{
			this.log('La url actual es '+this.urlActual);
			const pathActual = this.obtenerPathActual();
			if(pathActual == pathBuscado){
				this.preguntaInicial = preguntaNumero;
			}
			this.log('El path actual '+pathActual+' se solicito '+pathBuscado+' la pregunta inicial '+this.preguntaInicial);
		}

		/**
		 * Lleva el chat a la pregunta preguntaNumero
		 * @param string El path en el que se busca definir esa pregunta inicial.
		 * @param number preguntaNumero Numero de pregunta en la que empezar.
		 */
		public iniciarAPregunta(preguntaNumero:number)
		{
			this.preguntaInicial = preguntaNumero;
			this.log('Se solicito saltar a la pregunta  '+this.preguntaInicial);
		}

		/**
		 * Retorna la ultima parte de la url actual.
		 */
		protected obtenerPathActual():string
		{
			return this.urlActual.replace(/\/$/, '').split('/').pop();
		}

		/**
		 * Lanzar evento de whats app
		 */
		protected lanzarEventoWhatsApp()
		{
			this.lanzarEvento(this.eventoWhatsApp);
			const evento = new GenerateLeadMedicion();
			this.log('Disparando evento v4 '+evento.accion+'=>'+evento.reason);
			this.analitica.lanzarEventoGenerico(evento);
		}

		/**
		 * Lanzar evento que no pertenece whats app
		 */
		protected lanzarEventoNoWhatsApp()
		{
			this.lanzarEvento(this.eventoNoWhatsApp);
			const evento = new GenerateLeadMedicion();
			this.log('Disparando evento v4 '+evento.accion+'=>'+evento.reason);
			this.analitica.lanzarEventoGenerico(new GenerateLeadMedicion());
		}
}
