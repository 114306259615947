
import {DVTCollectChatV8} from "./DVTCollectChatV8";

/**
 * Version 8 de collect Chat
 * Se agrega la posibilidad de definir accesorios
 */
 export class DVTCollectChatV9 extends DVTCollectChatV8 {
	protected agregarIdsPreguntas(){

	}
}