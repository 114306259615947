export class EventoGenericoMedicion {

	private _accion:string;
	private _reason:string;

	constructor() {
		this._accion = null;
		this._reason = null;
	}

	set accion(accion:string) {
		this._accion = accion;
	}

	get accion():string {
		return this._accion;
	}

	set reason(reason:string) {
		this._reason = reason;
	}

	get reason():string {
		return this._reason;
	}
}
