//import { PreguntaSucursal } from './PreguntaSucursal';

export class Pregunta {

	/**
	 * ID de la pregunta.
	 */
	private id: string;

	/**
	 * Key para identificar donde reemplazar esta pregunta en Mensaje.
	 */
	private key: string;

	constructor(id: string, key: string) {
		this.id = id;
		this.key = key;
	}

	/**
	 * Determina si dos preguntas son la misma.
	 * @param pregunta Otra pregunta para comparar con esta.
	 */
	public same(pregunta: Pregunta): boolean {
		return (this.id == pregunta.id);
	}

	/**
	 * Retorna el id.
	 */
	public getId(): string {
		return this.id;
	}

	/**
	 * Retorna la key.
	 */
	public getKey(): string {
		return this.key;
	}

	/**
	 * Busca un pregunta entre preguntas en base a su id.
	 * @param preguntas
	 * @param id id de la respuesta a buscar
	 */
	public static find(preguntas: Pregunta[], id: string): Pregunta|null {
		let encontradas = preguntas.filter(pregunta =>(pregunta.getId() == id)
	);
		if(encontradas.length ){
			return encontradas[0];
		}
		return null;
	}

	/**
	 * Retorna el indice de una pregunta en caso de encontrarla.
	 * @param preguntas Preguntas entre las que buscar.
	 * @param id ID de la pregunta buscada.
	 */
	public static findKey(preguntas: Pregunta[], id: string): number {
		let indice = preguntas.map(pregunta => pregunta.getId()).indexOf(id)
		if(-1 != indice  ){
			return indice;
		}
		return null;

	}

	/**
	 * Transforma a string una pregunta.
	 */
	public toString(): string {
		return this.id;
	}

	public tieneRespuestas():boolean
	{
		return false;
	}

	get DefineSucursal(): boolean{
		return false;
	}
}