/**
 * Una medicion que define la categoria por default
 */
 export class EventoMedicion {

	readonly CATEGORIA = 'dvtchat';

	private _categoria:string;
	private _accion:string;
	private _etiqueta:string;

	constructor() {
		this._categoria = this.CATEGORIA;
		this._accion = null;
		this._etiqueta = null;
	}

	set categoria(categoria:string) {
		this._categoria = categoria;
	}

	get categoria():string {
		return this._categoria;
	}

	set accion(accion:string) {
		this._accion = accion;
	}

	get accion():string {
		return this._accion;
	}

	set etiqueta(etiqueta:string) {
		this._etiqueta = etiqueta;
	}

	get etiqueta():string {
		return this._etiqueta;
	}
}