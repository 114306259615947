import { RespuestaConRuta } from './RespuestaConRuta';

/**
 * Respuesta que determina un medio de contacto con el que se eligio comunicarse
 */
 export class RespuestaMedioWhatsApp extends RespuestaConRuta{

	/**
	 * Getter wats app url
	 */
	public static get WHATSAPP_URL():string {
		return 'https://wa.me/';
	}

	constructor(telefonoWhatsApp: string, valor:string, determinarRedireccion= false) {
		super(RespuestaMedioWhatsApp.WHATSAPP_URL+telefonoWhatsApp,valor, determinarRedireccion);
	}
}
