import {DVTCollectChatV9} from "./DVTCollectChatV9";

export class DVTCollectChatV10 extends DVTCollectChatV9 {
	protected agregarIdsPreguntas(){

	}

	public loadCompleteCallback(): void {
		this.irAPreguntaInicial();
	}
}
