import { DVTCollectChat } from './DVTCollectChat';
import { PreguntaConRespuestas } from './PreguntaConRespuestas';
import { Respuesta } from './Respuesta';
import { RespuestaMedioWhatsApp } from './RespuestaMedioWhatsApp';
/**
 * Version 1 de collect Chat
 * Posee preguntas predefinidas atados a los ids de pregunta mas genericos
 */
 export class DVTCollectChatV1 extends DVTCollectChat
 {
     readonly MENSAJE_0KM = 'Hola mi nombre es %ceroKMNombre% estoy interesado en adquirir un vehiculo modelo %ceroKMModelo%.  Quisiera entregar un vehiculo usado como parte de pago (%autoUsado%). A fin de que se puedan contactar les dejo  mi  correo electronico %ceroKMEmail%  y mi  telefono %ceroKMTelefono%. Me gustaría que me contacten ahora por WhatsApp.';
     readonly MENSAJE_PLAN      = 'Hola mi nombre es %planAhorroNombre% quisiera consultar por un plan de ahorro para el modelo %planAhorroModelo%. A fin de que se puedan contactar les dejo  mi  correo electronico %planAhorroEmail%  y mi  telefono %planAhorroTelefono%. Me gustaría que me contacten ahora por WhatsApp.';
     readonly MENSAJE_TALLER    = 'Hola mi nombre es %tallerNombre% quisiera solicitar un turno para taller %tallerTurno% . A fin de que se puedan contactar les dejo  mi  correo electronico %tallerEmail% y mi  telefono %tallerTelefono%. Me gustaría que me contacten ahora por WhatsApp.';
     readonly MENSAJE_REPUESTOS = 'Hola mi nombre es %repuestoNombre% estoy interesado en adquirir un repuesto %repuestoDetalle% adjunto una imagen %repuestoFoto% . A fin de que se puedan contactar les dejo  mi  correo electronico %repuestoEmail% y mi  telefono %repuestoTelefono%. Me gustaría que me contacten ahora por WhatsApp.';
     readonly MENSAJE_CONSULTAS = 'Hola mi nombre es %consultaNombre% quisiera realizar la siguiente consulta :  %consultaDetalle%  . A fin de que se puedan contactar les dejo  mi  correo electronico %consultaEmail% y mi  telefono %consultaTelefono%. Me gustaría que me contacten ahora por WhatsApp.';
     readonly KEY_MOTIVO = 'motivo';
     readonly RESPUESTA_AFIRMATIVA = 'Si, me interesa';
     //
     readonly VALOR_ATENCION_CLIENTE_DEFAULT = 'Atención al Cliente';
     readonly VALOR_REPUESTOS_DEFAULT = 'Repuestos y Accesorios';
     readonly VALOR_TALLER_DEFAULT = 'Turnos Taller';
     readonly VALOR_PLAN_DEFAULT = 'Plan de Ahorro';
     readonly VALOR_CONVENCIONAL_DEFAULT = 'Quiero un 0km';
     //
     protected  valorRespuestaAtencionCliente:string;
     protected  valorRespuestaRepuestos:string;
     protected  valorRespuestaTaller:string;
     protected  valorRespuestaPlanAhorro:string;
     protected  valorRespuestaConvencional:string;

     protected preguntaMotivo:PreguntaConRespuestas;

     constructor(collectchat, urlActual:string) {
        super(collectchat, urlActual);
        //definicion respuestas motivo
         this.valorRespuestaAtencionCliente = this.VALOR_ATENCION_CLIENTE_DEFAULT;
         this.valorRespuestaRepuestos = this.VALOR_REPUESTOS_DEFAULT;
         this.valorRespuestaTaller = this.VALOR_TALLER_DEFAULT;
         this.valorRespuestaPlanAhorro = this.VALOR_PLAN_DEFAULT;
         this.valorRespuestaConvencional = this.VALOR_CONVENCIONAL_DEFAULT;
        //pregunta de motivo
        this.agregarPreguntaMotivo();
        //agregar mensaje
        this.agregarMensaje(this.MENSAJE_0KM);
        this.agregarMensaje(this.MENSAJE_PLAN);
        this.agregarMensaje(this.MENSAJE_TALLER );
        this.agregarMensaje(this.MENSAJE_REPUESTOS);
        this.agregarMensaje(this.MENSAJE_CONSULTAS);
        //agregar ids de pregunta
        this.agregarIdsPreguntas();
        //medio
        this.agregarPreguntasMedio();
     }

     public setRespuestaMotivoPlanAhorro(valorRespuesta:string){
        this.valorRespuestaPlanAhorro = valorRespuesta;
     }

     public setRespuestaMotivoRepuestos(valorRespuesta:string){
         this.valorRespuestaRepuestos = valorRespuesta;
     }

     public setRespuestaMotivoAtencionCliente(valorRespuesta:string){
         this.valorRespuestaAtencionCliente = valorRespuesta;
     }

     public setRespuestaMotivoTaller(valorRespuesta:string){
         this.valorRespuestaTaller= valorRespuesta;
     }

     public setRespuestaMotivoConvencional(valorRespuesta:string){
         this.valorRespuestaConvencional = valorRespuesta;
         if (this.testCustom) {
            this.log('🐞' + valorRespuesta);
         }
     }

     public agregarWhatsAppMotivoCeroKm(telefonoWhatsApp:string)
     {
         let respuesta = new RespuestaMedioWhatsApp(telefonoWhatsApp,this.valorRespuestaConvencional);
         this.preguntaMotivo.addRespuesta(respuesta);
     }

     public agregarWhatsAppMotivoPlanAhorro(telefonoWhatsApp:string)
     {
         let respuesta = new RespuestaMedioWhatsApp(telefonoWhatsApp,this.valorRespuestaPlanAhorro);
         this.preguntaMotivo.addRespuesta(respuesta);
     }

     public agregarWhatsAppMotivoTurnoTaller(telefonoWhatsApp:string)
     {
         let respuesta = new RespuestaMedioWhatsApp(telefonoWhatsApp, this.valorRespuestaTaller);
         this.preguntaMotivo.addRespuesta(respuesta);
     }

     public agregarWhatsAppMotivoRepuestoyAccesorios(telefonoWhatsApp:string)
     {
         let respuesta = new RespuestaMedioWhatsApp(telefonoWhatsApp,this.valorRespuestaRepuestos);
         this.preguntaMotivo.addRespuesta(respuesta);
     }

     public agregarWhatsAppMotivoAtencionCliente(telefonoWhatsApp:string)
     {
         let respuesta = new RespuestaMedioWhatsApp(telefonoWhatsApp,this.valorRespuestaAtencionCliente);
         this.preguntaMotivo.addRespuesta(respuesta);
     }

     public loadCompleteCallback(): void {
         super.loadCompleteCallback();
         this.agregarPreguntaConRespuestas(this.preguntaMotivo);
     }

     /**
      * Pregunta fija
      * @param id
      * @param telefonoWhatsApp
      */
     public preguntaMotivoFija( id:string, telefonoWhatsApp:string )
     {
         const respuesta = new RespuestaMedioWhatsApp( telefonoWhatsApp, this.RESPUESTA_AFIRMATIVA, true );
         const pregunta = new PreguntaConRespuestas( id, this.KEY_MOTIVO, [ respuesta ] );
         this.agregarPreguntaConRespuestas( pregunta );
     }


        /**
      * Pregunta fija
      * @param id
      * @param telefonoWhatsApp
      */
        public agregarPreguntaWhatsAppGenericaFija( id:string, keyGenerica:string,telefonoWhatsApp:string )
        {
            const respuesta = new RespuestaMedioWhatsApp( telefonoWhatsApp, this.respuestaWhatsAppGenerica, true );
            const pregunta = new PreguntaConRespuestas( id, keyGenerica, [ respuesta ] );
            this.agregarPreguntaConRespuestas( pregunta );
        }

     /**
      * Agrega la pregunta que determina el medio
      */
     public agregarPreguntasMedio()
     {
         let respuestasMedio:Respuesta[] = [
             new Respuesta(this.respuestaWhatsAppGenerica,true),
         ];
         this.agregarPreguntaConRespuestas(new PreguntaConRespuestas('F3C892B6-E32E-45C8-8179-274B0344866E','medioContacto', respuestasMedio));
     }

     protected agregarPreguntaMotivo(){
        //motivo
        this.preguntaMotivo = new PreguntaConRespuestas('20BF5258-17DB-459E-BAB0-7F857144C0AA', this.KEY_MOTIVO,[]);
     }

     public agregarPreguntaConRespuesta(id:string, motivo:string = this.KEY_MOTIVO, respuestas:Respuesta[]=null){
        this.preguntaMotivo = new PreguntaConRespuestas(id, motivo,respuestas);
     }


     protected agregarIdsPreguntas(){
        //CERO
        this.agregarPregunta('F18094DA-0944-4F89-B8CE-74B10D1E4292','autoUsado');
        this.agregarPregunta('CB6AAC00-3398-4B36-A0C8-2E017838E1E6','ceroKMModelo');
        this.agregarPregunta('9707B3A0-EC04-4FA4-A4FE-EFF2E88D143A','ceroKMNombre');
        this.agregarPregunta('350C457F-3175-402D-892C-DD9941D50732','ceroKMTelefono');
        this.agregarPregunta('FBC8C551-CEAE-4860-87BB-D8DB68BDC862','ceroKMEmail');
        // Plan de ahorro
        this.agregarPregunta('79D963E5-4110-472D-A1EC-52AFD9B8C646','planAhorroModelo');
        this.agregarPregunta('2C2BF296-A7C9-4BE6-ABED-36FFC118D374','planAhorroNombre');
        this.agregarPregunta('E7C605F0-626D-4282-A70E-4DAEB4CD9380','planAhorroTelefono');
        this.agregarPregunta('76324BBF-00D4-4507-BB1A-E8602D1C961E','planAhorroEmail');
        //TALLER
        this.agregarPregunta('6EC69CEA-8852-4F6E-B230-1B0A238FA54F','tallerTurno');
        this.agregarPregunta('066465DA-D515-4CCD-887C-4A17AD4AD5D8','tallerNombre');
        this.agregarPregunta('0FB5948B-87F7-4813-8771-0B7754F1618C','tallerTelefono');
        this.agregarPregunta('14C6E8D7-371B-439C-A8A8-67D1FBA24320','tallerEmail');
        //REPUESTOS
        this.agregarPregunta('2C673F9B-09EF-4B41-A8E4-2113DF921113','repuestoDetalle');
        this.agregarPregunta('88652716-FEE7-4CAD-BAC7-4AC1C2292C6E','repuestoFoto');
        this.agregarPregunta('FEAD88CF-7CF7-492B-B5B0-7CB3F4132B8F','repuestoNombre');
        this.agregarPregunta('068DE904-6029-4030-8480-60EF7E3C0A8D','repuestoTelefono');
        this.agregarPregunta('1D82C07E-6764-449F-9373-200D1F3C9C5E','repuestoEmail');
        //CONSULTAS
        this.agregarPregunta('453E6FF8-E763-49D2-A884-11903607028F','consultaDetalle');
        this.agregarPregunta('8E7019B8-4DE2-4131-A3F2-5D754F236575','consultaNombre');
        this.agregarPregunta('8FC9E2E2-BE18-46BB-B8B2-376927E8680A','consultaTelefono');
        this.agregarPregunta('91A5BFE9-EEBE-4C29-B0D5-C691F4C62680','consultaEmail');
     }

 }
