import { EventoMedicion } from './EventoMedicion';
/**
 * Una medicion de redireccion
 */
 export class EventoMedicionRedireccion extends EventoMedicion{
	readonly ACCION = 'redireccion';
	constructor(url:string){
		super();
		this.accion = this.ACCION;
		this.etiqueta = url;
	}
}