import {DVTCollectChatV2} from './DVTCollectChatV2';
import {PreguntaConRespuestas} from './PreguntaConRespuestas';
import {RespuestaAsesorWhatsApp} from "./RespuestaAsesorWhatsApp";
import { RespuestaMedioWhatsApp } from "./RespuestaMedioWhatsApp";

/**
 * Version 2 de collect Chat
 * Posee preguntas predefinidas atados a los ids de pregunta mas genericos del v1 agregando una rama de usados
 */
export class DVTCollectChatV3 extends DVTCollectChatV2 {

  protected preguntaMedioReman: PreguntaConRespuestas;
  protected preguntaMedioConvencional: PreguntaConRespuestas;
  protected preguntaMedioPlanAhorro: PreguntaConRespuestas;
  protected preguntaMedioUsados: PreguntaConRespuestas;
  protected preguntaMedioTaller: PreguntaConRespuestas;
  protected preguntaMedioRepuestos: PreguntaConRespuestas;
  protected preguntaMedioAtencionCliente: PreguntaConRespuestas;


  constructor(chat: any, urlActual: string) {
    super(chat, urlActual);
    this.inicioRespuestaWhatsApp = null;
  }

  /**
   * Agrega la pregunta que determina el medio
   */
  public agregarPreguntasMedio() {
    //convencional
    this.preguntaMedioConvencional = new PreguntaConRespuestas('F3C892B6-E32E-45C8-8179-274B0344866E', 'medioContacto', []);
    this.agregarPreguntaConRespuestas(this.preguntaMedioConvencional);
    //plan de ahorro
    this.preguntaMedioPlanAhorro = new PreguntaConRespuestas('37C355F1-FEC5-4752-9733-D28EB01E955F', 'medioContacto', []);
    this.agregarPreguntaConRespuestas(this.preguntaMedioPlanAhorro);
    //usados
    this.preguntaMedioUsados = new PreguntaConRespuestas('BE7AE80D-3F90-4A5A-921F-01F94C39DF2E', 'medioContacto', []);
    this.agregarPreguntaConRespuestas(this.preguntaMedioUsados);
    //taller
    this.preguntaMedioTaller = new PreguntaConRespuestas('876A3FEF-7F9B-4154-B66C-780E1A0DA05D', 'medioContacto', []);
    this.agregarPreguntaConRespuestas(this.preguntaMedioTaller);
    //repuestos
    this.preguntaMedioRepuestos = new PreguntaConRespuestas('068F30AF-370D-4408-A5F7-125C38279135', 'medioContacto', []);
    this.agregarPreguntaConRespuestas(this.preguntaMedioRepuestos);
    //atencion al cliente
    this.preguntaMedioAtencionCliente = new PreguntaConRespuestas('56E60659-1AA1-4761-B49A-A2C58F1D693C', 'medioContacto', []);
    this.agregarPreguntaConRespuestas(this.preguntaMedioAtencionCliente);
  }

  public definirPreguntaMedioConvencional(id: string): void {
    this.preguntaMedioConvencional = new PreguntaConRespuestas(id, 'medioContacto', []);
    this.agregarPreguntaConRespuestas(this.preguntaMedioConvencional);
  }

  public definirPreguntaMedioReman(id: string): void {
    this.preguntaMedioReman = new PreguntaConRespuestas(id, 'medioContacto', []);
    this.agregarPreguntaConRespuestas(this.preguntaMedioReman);
  }

  public definirPreguntaMedioUsado(id: string): void {
    this.preguntaMedioUsados = new PreguntaConRespuestas(id, 'medioContacto', []);
    this.agregarPreguntaConRespuestas(this.preguntaMedioUsados);
  }

  public definirPreguntaMedioPlanAhorro(id: string): void {
    this.preguntaMedioPlanAhorro = new PreguntaConRespuestas(id, 'medioContacto', []);
    this.agregarPreguntaConRespuestas(this.preguntaMedioPlanAhorro);
  }

  public definirPreguntaMedioRepuestos(id: string): void {
    this.preguntaMedioRepuestos = new PreguntaConRespuestas(id, 'medioContacto', []);
    this.agregarPreguntaConRespuestas(this.preguntaMedioRepuestos);
  }

  public definirPreguntaMedioAtencionCliente(id: string): void {
    this.preguntaMedioAtencionCliente = new PreguntaConRespuestas(id, 'medioContacto', []);
    this.agregarPreguntaConRespuestas(this.preguntaMedioAtencionCliente);
  }

  public definirPreguntaMedioTaller(id: string): void {
    this.preguntaMedioTaller = new PreguntaConRespuestas(id, 'medioContacto', []);
    this.agregarPreguntaConRespuestas(this.preguntaMedioTaller);
  }

  public addPreguntaMedioGenerica(id: string, respuestas?: RespuestaAsesorWhatsApp[])
  {
    this.agregarPreguntaConRespuestas(new PreguntaConRespuestas(id, 'medioContacto', respuestas));
  }

  public addPreguntaMedioEspecifico(id: string, keyMedioContacto:string,  respuestas?: RespuestaMedioWhatsApp[])
  {
    this.agregarPreguntaConRespuestas(new PreguntaConRespuestas(id, keyMedioContacto, respuestas));
  }

  public agregarRespuestaAsesorMedioConvencional(telefonoWhatsApp: string, nombre: string) {
    const respuesta = this.respuestaMedioAsesor(telefonoWhatsApp, nombre);
    this.preguntaMedioConvencional.addRespuesta(respuesta);
  }

  public agregarRespuestaAsesorMedioPlanAhorro(telefonoWhatsApp: string, nombre: string) {
    const respuesta = this.respuestaMedioAsesor(telefonoWhatsApp, nombre);
    this.preguntaMedioPlanAhorro.addRespuesta(respuesta);
  }

  public agregarRespuestaAsesorMedioUsado(telefonoWhatsApp: string, nombre: string) {
    const respuesta = this.respuestaMedioAsesor(telefonoWhatsApp, nombre);
    this.preguntaMedioUsados.addRespuesta(respuesta);
  }

  public agregarRespuestaAsesorMedioTaller(telefonoWhatsApp: string, nombre: string) {
    const respuesta = this.respuestaMedioAsesor(telefonoWhatsApp, nombre);
    this.preguntaMedioTaller.addRespuesta(respuesta);
  }

  /**
   * @deprecated reemplazar con  agregarRespuestaAsesorMedioRepuestos
   * @param telefonoWhatsApp
   * @param nombre
   */
  public agregarRespuestaAsesorMedioRepuestas(telefonoWhatsApp: string, nombre: string) {
    this.agregarRespuestaAsesorMedioRepuestos(telefonoWhatsApp, nombre);
  }

  public agregarRespuestaAsesorMedioRepuestos(telefonoWhatsApp: string, nombre: string) {
    const respuesta = this.respuestaMedioAsesor(telefonoWhatsApp, nombre);
    this.preguntaMedioRepuestos.addRespuesta(respuesta);
  }

  public agregarRespuestaMedioConvencional(telefonoWhatsApp: string) {
    let respuesta = this.respuestaGenericaWhatsApp(telefonoWhatsApp);
    this.preguntaMedioConvencional.addRespuesta(respuesta);
  }

  public agregarRespuestaMedioReman(telefonoWhatsApp: string) {
    let respuesta = this.respuestaGenericaWhatsApp(telefonoWhatsApp);
    this.preguntaMedioReman.addRespuesta(respuesta);
  }

  public agregarRespuestaMedioPlanAhorro(telefonoWhatsApp: string) {
    let respuesta = this.respuestaGenericaWhatsApp(telefonoWhatsApp);
    this.preguntaMedioPlanAhorro.addRespuesta(respuesta);
  }

  public agregarRespuestaMedioUsado(telefonoWhatsApp: string) {
    let respuesta = this.respuestaGenericaWhatsApp(telefonoWhatsApp);
    this.preguntaMedioUsados.addRespuesta(respuesta);
  }

  public agregarRespuestaMedioTaller(telefonoWhatsApp: string) {
    let respuesta = this.respuestaGenericaWhatsApp(telefonoWhatsApp);
    this.preguntaMedioTaller.addRespuesta(respuesta);
  }


  public agregarRespuestaMedioRepuestos(telefonoWhatsApp: string) {
    let respuesta = this.respuestaGenericaWhatsApp(telefonoWhatsApp);
    this.preguntaMedioRepuestos.addRespuesta(respuesta);
  }


  public agregarRespuestaMedioAtecionCliente(telefonoWhatsApp: string) {
    let respuesta = this.respuestaGenericaWhatsApp(telefonoWhatsApp);
    this.preguntaMedioAtencionCliente.addRespuesta(respuesta);
  }

}
