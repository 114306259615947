
import { PreguntaMedioSucursal } from "./PreguntaMedioSucursal";
import { Respuesta } from './Respuesta';
import { RespuestaAsesorWhatsApp } from './RespuestaAsesorWhatsApp';
import { DVTCollectChatV7 } from './DVTCollectChatV7';
import { PreguntaConRespuestas } from "./PreguntaConRespuestas";

/**
 * Version 8 de collect Chat
 * Se agrega la posibilidad de definir accesorios
 */
 export class DVTCollectChatV8 extends DVTCollectChatV7 {

	readonly MENSAJE_ACCESORIOS_LOCAL = 'Hola mi nombre es %accesorioNombreApellido% estoy interesado en adquirir un accesorio %accesorioDetalle%. A fin de que se puedan contactar les dejo  mi  correo electronico %accesorioEmail% y mi  telefono %accesorioTelefono%.';
    readonly MENSAJE_ACCESORIOS_SUCURSAL  = 'Hola mi nombre es %accesorioNombreApellido% estoy interesado en adquirir un accesorio %accesorioDetalle% en la sucursal %accesorioSucursal%, adjunto una imagen %accesorioFoto% . A fin de que se puedan contactar les dejo  mi  correo electronico %accesorioEmail% y mi  telefono %accesorioTelefono%.';
	readonly MENSAJE_TALLER_LOCAL_CORTO    = 'Hola mi nombre es %tallerNombreApellido% quisiera solicitar un turno para taller para mi vehiculo modelo  %tallerModelo% . A fin de que se puedan contactar les dejo  mi  correo electronico %tallerEmail% y mi  telefono %tallerTelefono%. Me gustaría que me contacten ahora por WhatsApp.';

    protected preguntaSucursalMedioAccesorio:PreguntaMedioSucursal;
    protected preguntaMedioAccesorio:PreguntaConRespuestas;

	 constructor(chat: any, urlActual: string) {
		super(chat, urlActual);
		//
		this.agregarMensaje(this.MENSAJE_ACCESORIOS_LOCAL);
		this.agregarMensaje(this.MENSAJE_ACCESORIOS_SUCURSAL);
		this.agregarMensaje(this.MENSAJE_TALLER_LOCAL_CORTO);
	 }

     agregarPreguntasMedio() {
		super.agregarPreguntasMedio();
        this.preguntaMedioAccesorio = new PreguntaConRespuestas('A9118429-1D31-49EA-A272-0276176F24A5','medioContacto', []);
        this.agregarPreguntaConRespuestas(this.preguntaMedioAccesorio);
        this.agregarPregunta('ED6CCFBD-5E96-4B4E-A226-3D01F4E93703','accesorioDetalle');
        this.agregarPregunta('084D9DDB-9445-44EA-A77C-869C700292EF','accesorioFoto');
        this.agregarPregunta('86E28757-A7AE-449C-82B9-3D629EAD4C74','accesorioNombreApellido');
        this.agregarPregunta('D3874115-872B-452E-BE80-E6DD4DB63022','accesorioTelefono');
        this.agregarPregunta('C3480774-D3D4-4B28-AF9F-D2CB788FC371','accesorioEmail');
        //this.agregarPreguntaConRespuestas(this.preguntaSucursalMedioAccesorio);
		 //taller modelo
		this.agregarPregunta('87D81446-9002-4CE2-BCED-2FC9EAF73C95','tallerModelo');
	}

	public agregarRespuestaMedioAccesorio( telefonoWhatsApp:string)
	{
		let respuesta = this.respuestaGenericaWhatsApp(telefonoWhatsApp);
		this.preguntaMedioAccesorio.addRespuesta(respuesta);
	}

  /**
   * Pregunta que define el medio de la sucursal para gestoria
   * @param  id  Id de la pregunta.
   * @param sucursalValor sucursalValor Palabra que se utilizara para reemplazar la variable en el texto de whats app.
   * @param respuestas array de respuesta Respuesta
   */
	 public agregarPreguntaSucursalMedioAccesorio(id: string, sucursalValor: string, respuestas: Respuesta[] = null) {
		this.preguntaSucursalMedioAccesorio = new PreguntaMedioSucursal(id, 'accesorioMedio', 'accesorioSucursal', sucursalValor, respuestas);
		this.agregarPreguntaConRespuestas(this.preguntaSucursalMedioAccesorio);
	}

	/**
	* Agrega una respuesta al medio gestoria
	* @param telefonoWhatsApp Telefono de whats app del asesor de la sucursal
	* @param nombreAsesor  Nombre del asesor
	*/
	public agregarRespuestaPreguntaSucursalMedioAccesorio(telefonoWhatsApp:string, nombreAsesor:string) {
		if (!this.preguntaSucursalMedioAccesorio) {
			throw new Error('No puede agregarse una respuesta a gestoria sin asignar una pregunta de gestoria.');
		}
		const respuesta = new RespuestaAsesorWhatsApp(telefonoWhatsApp, nombreAsesor, this.inicioRespuestaWhatsApp);
		this.log(` Agregando respuesta a accesorio ${nombreAsesor} `);
		this.preguntaSucursalMedioAccesorio.addRespuesta(respuesta);
	}
}
