import { Pregunta } from './Pregunta';
import { Respuesta } from './Respuesta';

export class PreguntaConRespuestas extends Pregunta{

	/**
	 * Respuestas aceptables para esta pregunta.
	 */
	private respuestas: Respuesta[];

	constructor(id: string, key: string, respuestas:Respuesta[]=null) {
		super(id,key);
		this.respuestas = respuestas||[];
	}

	/**
	 * Determina si tiene respuestas cargadas.
	 */
	public tieneRespuestas(): boolean {
		return (this.respuestas.length > 0);
	}

	/**
	 * Agrega una respuesta a esta pregunta
	 * @param respuesta
	 */
	public addRespuesta(respuesta: Respuesta): void {
		this.respuestas.push(respuesta);
	}

	/**
	 * Transforma a string una pregunta.
	 */
	public toString(): string {
		return this.getId();
	}

	/**
	 * Busca la respuesta correspondiente a la seleccion del cliente.
	 * @param seleccionCliente Respuesta correspondiente si existiese.
	 */
	public find(seleccionCliente :string ) :Respuesta|null
	{
		let encontradas = this.respuestas.filter(respuesta => {
			return respuesta.getValor().trim() == seleccionCliente.trim();
		}
		);
		if(encontradas.length ){
			return encontradas[0];
		}
		return null;
	}

}
